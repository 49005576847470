<mat-card-title>
  <div class="row mb-3">
    <div class="col-auto h2">
      <b>{{ "SALE.ORDER_CARD.REQ_QUEST.TITLE_LIST" | translate }}</b>
      <button
        type="button"
        class="btn btn-primary"
        (click)="onCreateNewRequestWarehouse()"
        mat-button
      >
        {{ "SALE.ORDER_CARD.REQ_QUEST.CREATE_REQ_BTN" | translate }}
      </button>
    </div>
    <div>
      <mat-slide-toggle #slideRequest class="example-margin" color="primary">
        {{ slideRequest.checked ? "Архив" : "Актуальные запросы" }}
      </mat-slide-toggle>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content *ngIf="!slideRequest.checked">
  <div *ngIf="requestList$ | async as requestList">
    <div class="row" *ngFor="let item of requestList">
      <div class="col">
        <table
          class="table table-bordered"
          *ngIf="+item.order.category == 1 || +item.order.category == 2"
        >
          <thead>
            <tr>
              <td style="vertical-align: middle; width: 8rem">
                Запрос&ngsp;№&ngsp;{{ item.order.number }} <br />
                Заказ
                {{ item.order.orderNumber }}
              </td>
              <td colspan="2" style="vertical-align: middle">
                <div [ngSwitch]="+item.order.category">
                  <div *ngSwitchCase="1">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить наличие</b>
                      </div>
                      <div class="col-auto">
                        <span [ngSwitch]="+item.order.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="2">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить характеристики</b>
                      </div>
                      <div class="col-auto">
                        <mat-icon>settings</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Автор
                <button
                  mat-button
                  class="btn btn-warning btn-small"
                  matTooltip="Менеджер"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px">person_pin</mat-icon>
                </button>
              </td>
              <td colspan="2">
                {{ item.order.managerOrder }} |
                {{ item.order.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr *ngIf="!item.warehouse.number">
              <td>Исполнитель</td>
              <td colspan="2">
                Ответить до
                {{ item.order.dateEnd | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.order.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <b>{{ item.order.text_2 }} шт</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <b>{{ item.order.text_1 }}</b>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                {{ item.order.text_3 | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td colspan="2" class="text-danger">
                {{ item.order.comment }}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="table table-bordered"
          *ngIf="+item.order.category == 3 || +item.order.category == 4"
        >
          <thead>
            <tr>
              <td style="vertical-align: middle; width: 8rem">
                Запрос&ngsp;№&ngsp;{{ item.order.number }} <br />
                Заказ
                {{ item.order.orderNumber }}<br />&nbsp;
              </td>
              <td colspan="2" style="vertical-align: middle">
                <div [ngSwitch]="+item.order.category">
                  <div *ngSwitchCase="3">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить наличие</b>
                      </div>
                      <div class="col-auto">
                        <span [ngSwitch]="+item.order.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </div>
                      <div class="col-auto">
                        <button
                          *ngIf="item.warehouse.number"
                          mat-button
                          class="btn btn-success btn-small ml-4"
                          (click)="closeRequest(item.warehouse.number)"
                          matTooltip="Завершить запрос"
                          matTooltipClass="tooltip"
                        >
                          <mat-icon style="font-size: 12px">check</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="4">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить характеристики</b>
                      </div>
                      <div class="col-auto">
                        <mat-icon>settings</mat-icon>
                      </div>
                      <div class="col-auto">
                        <button
                          *ngIf="item.warehouse.number"
                          mat-button
                          class="btn btn-success btn-small ml-4"
                          (click)="closeRequest(item.warehouse.number)"
                          matTooltip="Завершить запрос"
                          matTooltipClass="tooltip"
                        >
                          <mat-icon style="font-size: 12px">check</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Автор
                <button
                  mat-button
                  class="btn btn-success btn-small"
                  matTooltip="Логист"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px"
                    >store_mall_directory</mat-icon
                  >
                </button>
              </td>
              <td colspan="2">
                {{ item.order.manager }} |
                {{ item.order.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr *ngIf="!item.warehouse.number">
              <td>Исполнитель</td>
              <td colspan="2">
                {{ item.order.managerOrder }} <br />Ответить до
                {{ item.order.dateEnd | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.order.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_2.split('|')[0] !=
                      +item.order.text_2.split('|')[1];
                    else showCount
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_2.split('|')[0] !=
                        +item.order.text_2.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_2.split("|")[0] }} шт</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_2.split('|')[0] !=
                        +item.order.text_2.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_2.split("|")[1] }} шт</b>
                  </span>
                </div>
                <ng-template #showCount>
                  <b>{{ +item.order.text_2.split("|")[1] }} шт</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>

              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_1.split('|')[0] !=
                      +item.order.text_1.split('|')[1];
                    else showWare
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_1.split('|')[0] !=
                        +item.order.text_1.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_1.split("|")[0] }} </b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_1.split('|')[0] !=
                        +item.order.text_1.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_1.split("|")[1] }} </b>
                  </span>
                </div>
                <ng-template #showWare>
                  <b>{{ +item.order.text_1.split("|")[1] }} </b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>

              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                <div
                  *ngIf="
                    (item.order.text_3.split('|')[0]
                      | date: 'dd-MM-YYYY HH:mm') !=
                      (item.order.text_3.split('|')[1]
                        | date: 'dd-MM-YYYY HH:mm');
                    else showDate
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        (item.order.text_3.split('|')[0]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.order.text_3.split('|')[1]
                          | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{
                      item.order.text_3.split("|")[0] | date: "dd-MM-YYYY HH:mm"
                    }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        (item.order.text_3.split('|')[0]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.order.text_3.split('|')[1]
                          | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{
                      item.order.text_3.split("|")[1] | date: "dd-MM-YYYY HH:mm"
                    }}
                  </span>
                </div>
                <ng-template #showDate>
                  {{
                    item.order.text_3.split("|")[1] | date: "dd-MM-YYYY HH:mm"
                  }}
                </ng-template>
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td
                colspan="2"
                [ngClass]="{
                  'text-danger': item.order.comment
                }"
              >
                {{ item.order.comment }}
              </td>
            </tr>

            <tr>
              <td>Результат</td>
              <th colspan="2">
                <button
                  mat-button
                  type="button"
                  class="btn btn-block"
                  [ngClass]="{
                    'btn-danger':
                      +item.order.text_1.split('|')[0] !=
                        +item.order.text_1.split('|')[1] ||
                      +item.order.text_2.split('|')[0] !=
                        +item.order.text_2.split('|')[1] ||
                      item.order.text_3.split('|')[0] !=
                        item.order.text_3.split('|')[1] ||
                      item.order.comment,
                    'btn-success':
                      +item.order.text_1.split('|')[0] ==
                        +item.order.text_1.split('|')[1] &&
                      +item.order.text_2.split('|')[0] ==
                        +item.order.text_2.split('|')[1] &&
                      item.order.text_3.split('|')[0] ==
                        item.order.text_3.split('|')[1] &&
                      !item.order.comment
                  }"
                >
                  {{
                    +item.order.text_1.split("|")[0] ==
                      +item.order.text_1.split("|")[1] &&
                    +item.order.text_2.split("|")[0] ==
                      +item.order.text_2.split("|")[1] &&
                    item.order.text_3.split("|")[0] ==
                      item.order.text_3.split("|")[1] &&
                    !item.order.comment
                      ? "Подтвержден"
                      : "Изменен"
                  }}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col">
        <table
          class="table table-bordered"
          *ngIf="
            item.warehouse &&
            (+item.order.category == 1 || +item.order.category == 2)
          "
        >
          <thead>
            <tr>
              <td style="width: 12rem">
                Ответ на запрос № {{ item.warehouse.number }} <br />
                Заказ
                {{ item.warehouse.orderNumber }}
              </td>
              <td colspan="2">
                <span [ngSwitch]="+item.warehouse.category">
                  <span *ngSwitchCase="1">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить наличие</b></span>
                      <span class="col-auto">
                        <span [ngSwitch]="+item.warehouse.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </span>
                    </span>
                  </span>
                  <span *ngSwitchCase="2">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить характеристики</b></span>
                      <span class="col-auto"
                        ><mat-icon>settings</mat-icon></span
                      >
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Исполнитель
                <button
                  mat-button
                  class="btn btn-success btn-small"
                  matTooltip="Логист"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px"
                    >store_mall_directory</mat-icon
                  >
                </button>
              </td>
              <td colspan="2">
                {{ item.warehouse.manager }} |
                {{ item.warehouse.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>

            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.warehouse.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_2 != +item.warehouse.text_2;
                    else showCount
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_2 != +item.warehouse.text_2
                    }"
                  >
                    <b>{{ item.order.text_2 }} шт</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_2 != +item.warehouse.text_2
                    }"
                  >
                    <b>{{ item.warehouse.text_2 }} шт</b>
                  </span>
                </div>
                <ng-template #showCount>
                  <b>{{ item.warehouse.text_2 }} шт</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>

              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_1 != +item.warehouse.text_1;
                    else showWare
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_1 != +item.warehouse.text_1
                    }"
                  >
                    <b>{{ item.order.text_1 }}</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_1 != +item.warehouse.text_1
                    }"
                  >
                    <b>{{ item.warehouse.text_1 }}</b>
                  </span>
                </div>
                <ng-template #showWare>
                  <b>{{ item.warehouse.text_1 }}</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>

              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                <div
                  *ngIf="
                    (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                      (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm');
                    else showDate
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{ item.order.text_3 | date: "dd-MM-YYYY HH:mm" }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                  </span>
                </div>
                <ng-template #showDate>
                  {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                </ng-template>
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td
                colspan="2"
                [ngClass]="{
                  'text-danger': item.order.comment != item.warehouse.comment
                }"
              >
                {{ item.warehouse.comment }}
              </td>
            </tr>
            <tr>
              <td>Результат</td>
              <th colspan="2">
                <button
                  mat-button
                  type="button"
                  class="btn btn-block"
                  [ngClass]="{
                    'btn-danger':
                      +item.order.text_2 != +item.warehouse.text_2 ||
                      +item.order.text_1 != +item.warehouse.text_1 ||
                      (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') ||
                      item.order.comment != item.warehouse.comment,
                    'btn-success':
                      +item.order.text_2 === +item.warehouse.text_2 &&
                      +item.order.text_1 == +item.warehouse.text_1 &&
                      (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') ==
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') &&
                      item.order.comment === item.warehouse.comment
                  }"
                >
                  {{
                    +item.order.text_2 === +item.warehouse.text_2 &&
                    +item.order.text_1 == +item.warehouse.text_1 &&
                    (item.order.text_3 | date: "dd-MM-YYYY HH:mm") ==
                      (item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm") &&
                    item.order.comment == item.warehouse.comment
                      ? "Подтвержден"
                      : "Изменен"
                  }}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="
            !item.warehouse &&
            (+item.order.category == 1 || +item.order.category == 2)
          "
        >
          <button
            mat-button
            class="btn btn-success"
            (click)="setResponse(item.order.number, item.order)"
          >
            Ответить на запрос
          </button>
        </div>
        <table
          class="table table-bordered"
          *ngIf="
            item.warehouse &&
            (+item.order.category == 3 || +item.order.category == 4)
          "
        >
          <thead>
            <tr>
              <td style="width: 12rem">
                Ответ на запрос № {{ item.warehouse.number }} <br />
                Заказ
                {{ item.warehouse.orderNumber }}
              </td>
              <td colspan="2">
                <span [ngSwitch]="+item.warehouse.category">
                  <span *ngSwitchCase="3">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить наличие</b></span>
                      <span class="col-auto pt-2" style="padding-bottom: 2px">
                        <span [ngSwitch]="+item.warehouse.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </span>
                    </span>
                  </span>
                  <span *ngSwitchCase="4">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить характеристики</b></span>
                      <span class="col-auto pt-2" style="padding-bottom: 2px"
                        ><mat-icon>settings</mat-icon></span
                      >
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Исполнитель
                <button
                  mat-button
                  class="btn btn-warning btn-small"
                  matTooltip="Менеджер"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px">person_pin</mat-icon>
                </button>
              </td>
              <td colspan="2">
                {{ item.warehouse.manager }}
                {{ item.warehouse.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>

            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.warehouse.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_2.split('|')[1] != +item.warehouse.text_2;
                    else showCount
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_2.split('|')[1] !=
                        +item.warehouse.text_2
                    }"
                  >
                    <b>{{ +item.order.text_2.split("|")[1] }} шт</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_2.split('|')[1] !=
                        +item.warehouse.text_2
                    }"
                  >
                    <b>{{ +item.warehouse.text_2 }} шт</b>
                  </span>
                </div>
                <ng-template #showCount>
                  <b>{{ +item.warehouse.text_2 }} шт</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>

              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_1.split('|')[1] != +item.warehouse.text_1;
                    else showWare
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_1.split('|')[1] !=
                        +item.warehouse.text_1
                    }"
                  >
                    <b>{{ +item.order.text_1.split("|")[1] }} </b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_1.split('|')[1] !=
                        +item.warehouse.text_1
                    }"
                  >
                    <b>{{ +item.warehouse.text_1 }} </b>
                  </span>
                </div>
                <ng-template #showWare>
                  <b>{{ +item.warehouse.text_1 }} </b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                <div
                  *ngIf="
                    (item.order.text_3.split('|')[1]
                      | date: 'dd-MM-YYYY HH:mm') !=
                      (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm');
                    else showDate
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        (item.order.text_3.split('|')[1]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{
                      item.order.text_3.split("|")[1] | date: "dd-MM-YYYY HH:mm"
                    }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        (item.order.text_3.split('|')[0]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                  </span>
                </div>
                <ng-template #showDate>
                  {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                </ng-template>
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td colspan="2">
                <div *ngIf="item.warehouse.comment; else showComment">
                  <span
                    [ngClass]="{
                      'text-through': item.warehouse.comment
                    }"
                  >
                    {{ item.order.comment }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger': item.warehouse.comment
                    }"
                  >
                    {{ item.warehouse.comment }}
                  </span>
                </div>
                <ng-template #showComment>
                  {{ item.order.comment }}
                </ng-template>
              </td>
            </tr>

            <tr>
              <td>Результат</td>
              <th colspan="2">
                <button
                  mat-button
                  type="button"
                  class="btn btn-block"
                  [ngClass]="{
                    'btn-danger':
                      +item.order.text_2.split('|')[1] !=
                        +item.warehouse.text_2 ||
                      +item.order.text_1.split('|')[1] !=
                        +item.warehouse.text_1 ||
                      (item.order.text_3.split('|')[1]
                        | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') ||
                      item.warehouse.comment,
                    'btn-success':
                      +item.order.text_2.split('|')[1] ==
                        +item.warehouse.text_2 &&
                      +item.order.text_1.split('|')[1] ==
                        +item.warehouse.text_1 &&
                      (item.order.text_3.split('|')[1]
                        | date: 'dd-MM-YYYY HH:mm') ==
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') &&
                      !item.warehouse.comment
                  }"
                >
                  {{
                    +item.order.text_2.split("|")[1] ==
                      +item.warehouse.text_2 &&
                    +item.order.text_1.split("|")[1] ==
                      +item.warehouse.text_1 &&
                    (item.order.text_3.split("|")[1]
                      | date: "dd-MM-YYYY HH:mm") ==
                      (item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm") &&
                    !item.warehouse.comment
                      ? "Подтвержден"
                      : "Изменен"
                  }}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="
            !item.warehouse &&
            (+item.order.category == 3 || +item.order.category == 4)
          "
        >
          Ожидается ответ
        </div>

        <div
          *ngIf="
            !item.warehouse &&
            (+item.order.category == 1 || +item.order.category == 2)
          "
        >
          Ожидается ответ
        </div>
      </div>
      <div class="col-12 mt-2 mb-4">
        <div
          style="
            border-top: 2px solid #27ae60;
            border-bottom: 2px solid #27ae60;
          "
        ></div>
      </div>
    </div>
  </div>
</mat-card-content>

<mat-card-content *ngIf="slideRequest.checked">
  <div *ngIf="requestListArchive$ | async as requestList">
    <div class="row" *ngFor="let item of requestList">
      <div class="col">
        <table
          class="table table-bordered"
          *ngIf="+item.order.category == 1 || +item.order.category == 2"
        >
          <thead>
            <tr>
              <td style="vertical-align: middle; width: 8rem">
                Запрос&ngsp;№&ngsp;{{ item.order.number }} <br />
                Заказ
                {{ item.order.orderNumber }}
              </td>
              <td colspan="2" style="vertical-align: middle">
                <div [ngSwitch]="+item.order.category">
                  <div *ngSwitchCase="1">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить наличие</b>
                      </div>
                      <div class="col-auto">
                        <span [ngSwitch]="+item.order.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="2">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить характеристики</b>
                      </div>
                      <div class="col-auto">
                        <mat-icon>settings</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Автор
                <button
                  mat-button
                  class="btn btn-warning btn-small"
                  matTooltip="Менеджер"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px">person_pin</mat-icon>
                </button>
              </td>
              <td colspan="2">
                {{ item.order.managerOrder }} |
                {{ item.order.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr *ngIf="!item.warehouse.number">
              <td>Исполнитель</td>
              <td colspan="2">
                Ответить до
                {{ item.order.dateEnd | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.order.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <b>{{ item.order.text_2 }} шт</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <b>{{ item.order.text_1 }}</b>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                {{ item.order.text_3 | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td colspan="2" class="text-danger">
                {{ item.order.comment }}
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="table table-bordered"
          *ngIf="+item.order.category == 3 || +item.order.category == 4"
        >
          <thead>
            <tr>
              <td style="vertical-align: middle; width: 8rem">
                Запрос&ngsp;№&ngsp;{{ item.order.number }} <br />
                Заказ
                {{ item.order.orderNumber }}<br />&nbsp;
              </td>
              <td colspan="2" style="vertical-align: middle">
                <div [ngSwitch]="+item.order.category">
                  <div *ngSwitchCase="3">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить наличие</b>
                      </div>
                      <div class="col-auto">
                        <span [ngSwitch]="+item.order.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="4">
                    <div class="row align-items-center">
                      <div class="col-12">
                        <b>Уточнить характеристики</b>
                      </div>
                      <div class="col-auto">
                        <mat-icon>settings</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Автор
                <button
                  mat-button
                  class="btn btn-success btn-small"
                  matTooltip="Логист"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px"
                    >store_mall_directory</mat-icon
                  >
                </button>
              </td>
              <td colspan="2">
                {{ item.order.manager }} |
                {{ item.order.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr *ngIf="!item.warehouse.number">
              <td>Исполнитель</td>
              <td colspan="2">
                {{ item.order.managerOrder }} <br />Ответить до
                {{ item.order.dateEnd | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.order.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_2.split('|')[0] !=
                      +item.order.text_2.split('|')[1];
                    else showCount
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_2.split('|')[0] !=
                        +item.order.text_2.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_2.split("|")[0] }} шт</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_2.split('|')[0] !=
                        +item.order.text_2.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_2.split("|")[1] }} шт</b>
                  </span>
                </div>
                <ng-template #showCount>
                  <b>{{ +item.order.text_2.split("|")[1] }} шт</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>

              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_1.split('|')[0] !=
                      +item.order.text_1.split('|')[1];
                    else showWare
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_1.split('|')[0] !=
                        +item.order.text_1.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_1.split("|")[0] }} </b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_1.split('|')[0] !=
                        +item.order.text_1.split('|')[1]
                    }"
                  >
                    <b>{{ +item.order.text_1.split("|")[1] }} </b>
                  </span>
                </div>
                <ng-template #showWare>
                  <b>{{ +item.order.text_1.split("|")[1] }} </b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>

              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                <div
                  *ngIf="
                    (item.order.text_3.split('|')[0]
                      | date: 'dd-MM-YYYY HH:mm') !=
                      (item.order.text_3.split('|')[1]
                        | date: 'dd-MM-YYYY HH:mm');
                    else showDate
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        (item.order.text_3.split('|')[0]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.order.text_3.split('|')[1]
                          | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{
                      item.order.text_3.split("|")[0] | date: "dd-MM-YYYY HH:mm"
                    }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        (item.order.text_3.split('|')[0]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.order.text_3.split('|')[1]
                          | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{
                      item.order.text_3.split("|")[1] | date: "dd-MM-YYYY HH:mm"
                    }}
                  </span>
                </div>
                <ng-template #showDate>
                  {{
                    item.order.text_3.split("|")[1] | date: "dd-MM-YYYY HH:mm"
                  }}
                </ng-template>
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td
                colspan="2"
                [ngClass]="{
                  'text-danger': item.order.comment
                }"
              >
                {{ item.order.comment }}
              </td>
            </tr>

            <tr>
              <td>Результат</td>
              <th colspan="2">
                <button
                  mat-button
                  type="button"
                  class="btn btn-block"
                  [ngClass]="{
                    'btn-danger':
                      +item.order.text_1.split('|')[0] !=
                        +item.order.text_1.split('|')[1] ||
                      +item.order.text_2.split('|')[0] !=
                        +item.order.text_2.split('|')[1] ||
                      item.order.text_3.split('|')[0] !=
                        item.order.text_3.split('|')[1] ||
                      item.order.comment,
                    'btn-success':
                      +item.order.text_1.split('|')[0] ==
                        +item.order.text_1.split('|')[1] &&
                      +item.order.text_2.split('|')[0] ==
                        +item.order.text_2.split('|')[1] &&
                      item.order.text_3.split('|')[0] ==
                        item.order.text_3.split('|')[1] &&
                      !item.order.comment
                  }"
                >
                  {{
                    +item.order.text_1.split("|")[0] ==
                      +item.order.text_1.split("|")[1] &&
                    +item.order.text_2.split("|")[0] ==
                      +item.order.text_2.split("|")[1] &&
                    item.order.text_3.split("|")[0] ==
                      item.order.text_3.split("|")[1] &&
                    !item.order.comment
                      ? "Подтвержден"
                      : "Изменен"
                  }}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col">
        <table
          class="table table-bordered"
          *ngIf="
            item.warehouse &&
            (+item.order.category == 1 || +item.order.category == 2)
          "
        >
          <thead>
            <tr>
              <td style="width: 12rem">
                Ответ на запрос № {{ item.warehouse.number }} <br />
                Заказ
                {{ item.warehouse.orderNumber }}
              </td>
              <td colspan="2">
                <span [ngSwitch]="+item.warehouse.category">
                  <span *ngSwitchCase="1">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить наличие</b></span>
                      <span class="col-auto">
                        <span [ngSwitch]="+item.warehouse.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </span>
                    </span>
                  </span>
                  <span *ngSwitchCase="2">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить характеристики</b></span>
                      <span class="col-auto"
                        ><mat-icon>settings</mat-icon></span
                      >
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Исполнитель
                <button
                  mat-button
                  class="btn btn-success btn-small"
                  matTooltip="Логист"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px"
                    >store_mall_directory</mat-icon
                  >
                </button>
              </td>
              <td colspan="2">
                {{ item.warehouse.manager }} |
                {{ item.warehouse.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>

            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.warehouse.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_2 != +item.warehouse.text_2;
                    else showCount
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_2 != +item.warehouse.text_2
                    }"
                  >
                    <b>{{ item.order.text_2 }} шт</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_2 != +item.warehouse.text_2
                    }"
                  >
                    <b>{{ item.warehouse.text_2 }} шт</b>
                  </span>
                </div>
                <ng-template #showCount>
                  <b>{{ item.warehouse.text_2 }} шт</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>

              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_1 != +item.warehouse.text_1;
                    else showWare
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_1 != +item.warehouse.text_1
                    }"
                  >
                    <b>{{ item.order.text_1 }}</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_1 != +item.warehouse.text_1
                    }"
                  >
                    <b>{{ item.warehouse.text_1 }}</b>
                  </span>
                </div>
                <ng-template #showWare>
                  <b>{{ item.warehouse.text_1 }}</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>

              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                <div
                  *ngIf="
                    (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                      (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm');
                    else showDate
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{ item.order.text_3 | date: "dd-MM-YYYY HH:mm" }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                  </span>
                </div>
                <ng-template #showDate>
                  {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                </ng-template>
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td
                colspan="2"
                [ngClass]="{
                  'text-danger': item.order.comment != item.warehouse.comment
                }"
              >
                {{ item.warehouse.comment }}
              </td>
            </tr>
            <tr>
              <td>Результат</td>
              <th colspan="2">
                <button
                  mat-button
                  type="button"
                  class="btn btn-block"
                  [ngClass]="{
                    'btn-danger':
                      +item.order.text_2 != +item.warehouse.text_2 ||
                      +item.order.text_1 != +item.warehouse.text_1 ||
                      (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') ||
                      item.order.comment != item.warehouse.comment,
                    'btn-success':
                      +item.order.text_2 === +item.warehouse.text_2 &&
                      +item.order.text_1 == +item.warehouse.text_1 &&
                      (item.order.text_3 | date: 'dd-MM-YYYY HH:mm') ==
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') &&
                      item.order.comment === item.warehouse.comment
                  }"
                >
                  {{
                    +item.order.text_2 === +item.warehouse.text_2 &&
                    +item.order.text_1 == +item.warehouse.text_1 &&
                    (item.order.text_3 | date: "dd-MM-YYYY HH:mm") ==
                      (item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm") &&
                    item.order.comment == item.warehouse.comment
                      ? "Подтвержден"
                      : "Изменен"
                  }}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="
            !item.warehouse &&
            (+item.order.category == 1 || +item.order.category == 2)
          "
        >
          <button
            mat-button
            class="btn btn-success"
            (click)="setResponse(item.order.number, item.order)"
          >
            Ответить на запрос
          </button>
        </div>
        <table
          class="table table-bordered"
          *ngIf="
            item.warehouse &&
            (+item.order.category == 3 || +item.order.category == 4)
          "
        >
          <thead>
            <tr>
              <td style="width: 12rem">
                Ответ на запрос № {{ item.warehouse.number }} <br />
                Заказ
                {{ item.warehouse.orderNumber }}
              </td>
              <td colspan="2">
                <span [ngSwitch]="+item.warehouse.category">
                  <span *ngSwitchCase="3">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить наличие</b></span>
                      <span class="col-auto pt-2" style="padding-bottom: 2px">
                        <span [ngSwitch]="+item.warehouse.delivery">
                          <mat-icon
                            *ngSwitchCase="6"
                            matTooltip="Курьером"
                            matTooltipClass="tooltip"
                            >local_taxi</mat-icon
                          >
                          <mat-icon
                            *ngSwitchCase="7"
                            matTooltip="Самовывоз"
                            matTooltipClass="tooltip"
                            >directions_run</mat-icon
                          >
                          <mat-icon
                            *ngSwitchDefault
                            matTooltip="Перевозчик"
                            matTooltipClass="tooltip"
                            >local_shipping</mat-icon
                          >
                        </span>
                      </span>
                    </span>
                  </span>
                  <span *ngSwitchCase="4">
                    <span class="row align-items-center">
                      <span class="col-12"><b>Уточнить характеристики</b></span>
                      <span class="col-auto pt-2" style="padding-bottom: 2px"
                        ><mat-icon>settings</mat-icon></span
                      >
                    </span>
                  </span>
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td>
                Исполнитель
                <button
                  mat-button
                  class="btn btn-warning btn-small"
                  matTooltip="Менеджер"
                  matTooltipClass="tooltip"
                >
                  <mat-icon style="font-size: 12px">person_pin</mat-icon>
                </button>
              </td>
              <td colspan="2">
                {{ item.warehouse.manager }}
                {{ item.warehouse.dateCreate | date: "dd-MM-YYYY HH:mm" }}
              </td>
            </tr>

            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
              >
                Товар
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-top: 3px solid #000 !important;
                "
                colspan="2"
              >
                <b>{{ item.warehouse.title }}</b>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Кол-во</td>
              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_2.split('|')[1] != +item.warehouse.text_2;
                    else showCount
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_2.split('|')[1] !=
                        +item.warehouse.text_2
                    }"
                  >
                    <b>{{ +item.order.text_2.split("|")[1] }} шт</b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_2.split('|')[1] !=
                        +item.warehouse.text_2
                    }"
                  >
                    <b>{{ +item.warehouse.text_2 }} шт</b>
                  </span>
                </div>
                <ng-template #showCount>
                  <b>{{ +item.warehouse.text_2 }} шт</b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td style="border-left: 3px solid #000 !important">Склад</td>

              <td style="border-right: 3px solid #000 !important" colspan="2">
                <div
                  *ngIf="
                    +item.order.text_1.split('|')[1] != +item.warehouse.text_1;
                    else showWare
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        +item.order.text_1.split('|')[1] !=
                        +item.warehouse.text_1
                    }"
                  >
                    <b>{{ +item.order.text_1.split("|")[1] }} </b>
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        +item.order.text_1.split('|')[1] !=
                        +item.warehouse.text_1
                    }"
                  >
                    <b>{{ +item.warehouse.text_1 }} </b>
                  </span>
                </div>
                <ng-template #showWare>
                  <b>{{ +item.warehouse.text_1 }} </b>
                </ng-template>
              </td>
            </tr>
            <tr>
              <td
                style="
                  border-left: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
              >
                Срок
              </td>
              <td
                style="
                  border-right: 3px solid #000 !important;
                  border-bottom: 3px solid #000 !important;
                "
                colspan="2"
              >
                <div
                  *ngIf="
                    (item.order.text_3.split('|')[1]
                      | date: 'dd-MM-YYYY HH:mm') !=
                      (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm');
                    else showDate
                  "
                >
                  <span
                    [ngClass]="{
                      'text-through':
                        (item.order.text_3.split('|')[1]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{
                      item.order.text_3.split("|")[1] | date: "dd-MM-YYYY HH:mm"
                    }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger':
                        (item.order.text_3.split('|')[0]
                          | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm')
                    }"
                  >
                    {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                  </span>
                </div>
                <ng-template #showDate>
                  {{ item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm" }}
                </ng-template>
              </td>
            </tr>
            <tr>
              <td>Коментарий (Приоритет 2)</td>
              <td colspan="2">
                <div *ngIf="item.warehouse.comment; else showComment">
                  <span
                    [ngClass]="{
                      'text-through': item.warehouse.comment
                    }"
                  >
                    {{ item.order.comment }}
                  </span>
                  &nbsp;&nbsp;/&nbsp;&nbsp;
                  <span
                    [ngClass]="{
                      'text-danger': item.warehouse.comment
                    }"
                  >
                    {{ item.warehouse.comment }}
                  </span>
                </div>
                <ng-template #showComment>
                  {{ item.order.comment }}
                </ng-template>
              </td>
            </tr>

            <tr>
              <td>Результат</td>
              <th colspan="2">
                <button
                  mat-button
                  type="button"
                  class="btn btn-block"
                  [ngClass]="{
                    'btn-danger':
                      +item.order.text_2.split('|')[1] !=
                        +item.warehouse.text_2 ||
                      +item.order.text_1.split('|')[1] !=
                        +item.warehouse.text_1 ||
                      (item.order.text_3.split('|')[1]
                        | date: 'dd-MM-YYYY HH:mm') !=
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') ||
                      item.warehouse.comment,
                    'btn-success':
                      +item.order.text_2.split('|')[1] ==
                        +item.warehouse.text_2 &&
                      +item.order.text_1.split('|')[1] ==
                        +item.warehouse.text_1 &&
                      (item.order.text_3.split('|')[1]
                        | date: 'dd-MM-YYYY HH:mm') ==
                        (item.warehouse.text_3 | date: 'dd-MM-YYYY HH:mm') &&
                      !item.warehouse.comment
                  }"
                >
                  {{
                    +item.order.text_2.split("|")[1] ==
                      +item.warehouse.text_2 &&
                    +item.order.text_1.split("|")[1] ==
                      +item.warehouse.text_1 &&
                    (item.order.text_3.split("|")[1]
                      | date: "dd-MM-YYYY HH:mm") ==
                      (item.warehouse.text_3 | date: "dd-MM-YYYY HH:mm") &&
                    !item.warehouse.comment
                      ? "Подтвержден"
                      : "Изменен"
                  }}
                </button>
              </th>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="
            !item.warehouse &&
            (+item.order.category == 3 || +item.order.category == 4)
          "
        >
          Ожидается ответ
        </div>

        <div
          *ngIf="
            !item.warehouse &&
            (+item.order.category == 1 || +item.order.category == 2)
          "
        >
          Ожидается ответ
        </div>
      </div>
      <div class="col-12 mt-2 mb-4">
        <div
          style="
            border-top: 2px solid #27ae60;
            border-bottom: 2px solid #27ae60;
          "
        ></div>
      </div>
    </div>
  </div>
</mat-card-content>

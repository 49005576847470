
<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>{{'ANALYTIC.ROMI.MARK_MODEL_TITLE'|translate}} - {{this.dialogData.mark}} {{this.dialogData.model}}</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              [matTooltip]="'MODAL_BUTTON.CLOSE'|translate"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<p-table
  *ngIf="infoList$ |async as infoList;else loading"
  [columns]="infoList.headerList"
  [value]="infoList.orderList"
  [rowsPerPageOptions]="[10,25,50,100]"
  [paginator]="true"
  currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
  [filterDelay]="0"
  autoLayout="true"
  [rows]="10"
  selectionMode="single"
  styleClass="p-datatable-gridlines p-datatable-sm">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        style="font-weight: 400;font-size: 12px">
        <p-sortIcon [field]="col.field"></p-sortIcon>
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td>{{rowData.filter}}</td>
      <td>{{rowData.grossSale}}</td>
      <td>{{rowData.investments}}</td>
      <td [ngClass]="{
            'bg-danger':(rowData.romi<0),
            'bg-success':(rowData.romi>=50)
            }">{{rowData.romi}}</td>

    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr [class]="infoList.footer.class">
      <td *ngIf="infoList.footer.colspan" [colSpan]="infoList.footer.colspan"> Итого</td>
      <td>{{infoList.footer.grossSale}}</td>
      <td>{{infoList.footer.investments}}</td>
      <td>{{infoList.footer.romi}}</td>
    </tr>
  </ng-template>


</p-table>


<ng-template #loading>
  <div class="d-flex justify-content-center my-5" style="height: 600px">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>

<div class="container-fluid">
  <div class="row my-2">
    <div class="col">
      <mat-card class="card-box">
        <mat-card-title>Картотека клиентов</mat-card-title>
        <mat-card-content>
          <form [formGroup]="formFilter" (ngSubmit)="loadUserCardList()">
            <div class="row align-items-center">
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>Телефон</mat-label>
                  <input matInput
                         type="text"
                         prefix="+38"
                         [mask]="' (000) 000-00-99'"
                         formControlName="phone"
                  />
                </mat-form-field>
              </div>
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>ФИО</mat-label>
                  <input matInput
                         type="text"
                         formControlName="name"
                  />
                </mat-form-field>
              </div>
              <div class="col-auto"   *ngIf="(typeUserList$|async) as typeUserList" >
                <mat-form-field appearance="outline">
                  <mat-label>Тип</mat-label>
                  <mat-select formControlName="type">
                    <mat-option *ngFor="let item of typeUserList" [value]="item.value">{{item.text}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-auto"  *ngIf="(actionUserList$|async) as actionUserList">
                <mat-form-field appearance="outline">
                  <mat-label>Статус</mat-label>
                  <mat-select formControlName="action">
                    <mat-option *ngFor="let item of actionUserList" [value]="item.value">{{item.text}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
<!--              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>Дата регистрации</mat-label>
                  <input matInput formControlName="date" [matDatepicker]="picker2">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field>
              </div>-->
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>С</mat-label>
                  <input matInput
                         type="text"
                         formControlName="start"
                  />
                </mat-form-field>
              </div>
              <div class="col-auto">
                <mat-form-field appearance="outline">
                  <mat-label>Кол-во</mat-label>
                  <input matInput
                         type="text"
                         formControlName="limit"
                  />
                </mat-form-field>
              </div>
              <div class="col-auto">
                <button type="submit" mat-button class="btn btn-success">Отправить</button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row my-2">
    <div class="col">
      <p-table #dt1 *ngIf="(userCardList$| async) as userCardList; else loading" [value]="userCardList"
               [rowsPerPageOptions]="[10,25,50,100,500,1000,2000,5000]"
               [rows]="15"
               sortMode="multiple"
               selectionMode="single"
               styleClass=" p-datatable-sm p-datatable-gridlines p-datatable-striped"
               [paginator]="true" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} строчек"
               [globalFilterFields]="['orderNumber','planDateSend','recipient','phone','deliveryName']"
      >

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem; font-weight: 400; font-size: 12px"
                [pSortableColumn]="'row'"
            >№
              <p-sortIcon [field]="'row'"></p-sortIcon>

            </th>
            <th  style=" font-weight: 400; font-size: 12px"
                 [pSortableColumn]="'name'">ФИО
              <p-sortIcon [field]="'name'"></p-sortIcon>

            </th>
            <th style="width: 15rem; font-weight: 400; font-size: 12px"
                [pSortableColumn]="'phone'">Телефон
              <p-sortIcon [field]="'phone'"></p-sortIcon>

            </th>
            <th  style="width: 15rem; font-weight: 400; font-size: 12px"
                 [pSortableColumn]="'date'">Дата регистрации в кабинете
              <p-sortIcon [field]="'date'"></p-sortIcon>
            </th>
            <th  style="width: 10em; font-weight: 400; font-size: 12px"
                 [pSortableColumn]="'action'">Статус
              <p-sortIcon [field]="'action'"></p-sortIcon>

            </th>
            <th  style="width: 10em; font-weight: 400; font-size: 12px"
                 [pSortableColumn]="'type'">Тип
              <p-sortIcon [field]="'type'"></p-sortIcon>

            </th>
            <th style="width: 10em; font-weight: 400; font-size: 12px"
                [pSortableColumn]="'balance'">Баланс
              <p-sortIcon [field]="'balance'"></p-sortIcon>

            </th>
            <th style="width: 3rem;"></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-userCardList>

          <tr>
            <td>{{userCardList.row}}</td>
            <td>{{userCardList.name}}</td>
            <td>{{userCardList.phone}}</td>
            <td>{{userCardList.date|date:'dd-MM-yyyy HH:mm:ss'}}</td>
            <td>{{userCardList.action}}</td>
            <td>{{userCardList.type}}</td>
            <td>{{userCardList.balance}}</td>
            <td><a [href]="'/crm/user-card/'+userCardList.hash" mat-icon-button><mat-icon>more_vert</mat-icon></a></td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template #loading>
        <div class="d-flex justify-content-center my-5">
          <mat-spinner diameter="25"></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>

</div>

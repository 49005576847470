import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { TaskComment, TaskInfo } from '../core/task';
import { TaskService } from '../core/task.service';
import { ActivatedRoute } from '@angular/router';
import { filter, takeUntil, tap, timeInterval } from 'rxjs/operators';
import { OtherSpr } from '../../../../core/interfaces/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { TaskDialogConfirmTaskComponent } from '../task-dialog-confirm-task/task-dialog-confirm-task.component';
import { TaskDialogChangeTaskComponent } from '../task-dialog-change-task/task-dialog-change-task.component';
import { TaskDialogChangeManagerComponent } from '../task-dialog-change-manager/task-dialog-change-manager.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  providers: [TaskService],
})
export class TaskComponent implements OnInit, OnDestroy {
  @Input() taskIdForm?: number;
  @Input() src = false;
  form: FormGroup;
  taskId: number;
  taskInfo$: Observable<TaskInfo>;
  taskInfoData: TaskInfo;
  taskComment$: Observable<TaskComment[]>;
  categoryList$: Observable<OtherSpr[]>;
  destroy = new Subject();

  dateBegin: FormControl;
  dateEnd: FormControl;
  category: FormControl;
  manager: FormControl;
  showEditManager: number[]; // показать кнопку изменить исполнителя
  workmanSelfId: number;
  comment;
  constructor(
    private task: TaskService,
    private activateRoute: ActivatedRoute,
    private srv: SrvService,
    private dialog: MatDialog
  ) {
    this.dateBegin = new FormControl('');
    this.dateEnd = new FormControl('');
    this.category = new FormControl('');
    this.manager = new FormControl('');

    this.form = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });

    this.srv.userPerm.pipe(filter((val) => val != null)).subscribe((value) => {
      console.log('perm', value);
      this.showEditManager = value.permission.filter((val) => +val === 42);
      this.workmanSelfId = +value.workmanId;
    });
  }

  ngOnInit(): void {
    if (this.src) {
      this.taskId = this.taskIdForm;
    } else {
      this.taskId = this.activateRoute.snapshot.params.id;
    }

    this.loadTaskInfo();
    this.loadTaskComment();
    this.loadCategory();
  }

  // загрузить информацию по задаче
  loadTaskInfo(): void {
    this.taskInfo$ = this.task.loadTaskInfo(this.taskId).pipe(
      tap((val) => {
        this.taskInfoData = val;
        this.dateBegin.setValue(this.srv.convertDateTimeInput(val.dateStart));
        this.dateEnd.setValue(this.srv.convertDateTimeInput(val.dateEnd));
        this.category.setValue(+val.category);
        this.manager.setValue(val.manager);
      })
    );
  }

  // загрузить комментарии по задаче
  loadTaskComment(): void {
    this.taskComment$ = this.task.loadTaskComment(this.taskId).pipe(
      tap((val) => {
        this.comment = val[val.length - 1].comment;
      })
    );
  }

  // загрузить категории
  loadCategory(): void {
    this.categoryList$ = this.task.loadCategory();
  }

  // взять задачу в работу
  setTaskToWork(): void {
    this.task
      .setTaskToWork(this.taskId)
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (val.code && +val.code === 200) {
          this.loadTaskInfo();
          this.srv.successMessage('Задача взятя в работу');
        } else {
          this.srv.errorMessage('Ошибка обратитесь к администратору');
        }
      });
  }

  // Завершить задачу
  onConfirmTask(): void {
    const dialog = this.dialog.open(TaskDialogConfirmTaskComponent, {
      data: {
        id: this.taskId,
        category: +this.category.value,
        comment: +this.category.value === 11 ? this.comment : '',
      },
      maxHeight: '95vh',
      maxWidth: '95vw',
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.code && +val.code === 200) {
        this.loadTaskInfo();
        this.loadTaskComment();
      }
    });
  }

  // Изменить сроки выполнения задачи
  onChangeDate(): void {
    const dialog = this.dialog.open(TaskDialogChangeTaskComponent, {
      data: {
        id: this.taskId,
        category: this.category.value,
        dateEnd: this.dateEnd.value,
      },
      maxHeight: '95vh',
      maxWidth: '95vw',
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.code && +val.code === 200) {
        this.loadTaskInfo();
        this.loadTaskComment();
      }
    });
  }

  // Добавить комментарий
  addComment(): any {
    if (this.form.invalid) {
      this.srv.successMessage('Нельзя записать пустой комментарий');

      return false;
    }

    this.task
      .addComment(this.taskId, this.form.value.comment)
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (val.code && +val.code === 200) {
          this.loadTaskComment();
          this.form.get('comment').setValue('');
          this.srv.successMessage('Задача взятя в работу');
        } else {
          this.srv.errorMessage('Ошибка обратитесь к администратору');
        }
      });
  }

  editManager(): void {
    const dialog = this.dialog.open(TaskDialogChangeManagerComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: {
        userId: this.taskInfoData.manager,
        taskId: this.taskId,
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.code && +val.code === 200) {
        this.loadTaskInfo();
        this.srv.successMessage('Данные исполнитель изменен');
      }
    });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}

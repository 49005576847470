import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { RequestBody } from '../interfaces/interfaces';
import { SrvService } from 'repositories';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuardService implements CanActivate {
  access: number[];
  permissionId;

  constructor(
    private srv: SrvService,
    private router: Router,
    private api: ApiService
  ) {}

  getRouterPermission(route): Observable<boolean> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getRouterPermission',
      methodProperties: {
        type: 1,
        route,
      },
    };

    return this.api.post_jwt(q).pipe(
      take(1),
      map((isAccessSetting) => {
        if (isAccessSetting) {
          return true;
        }
        // window.location.href = '/crm';
        this.router.navigate(['/crm']);
      })
    );
  }
  //
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.getRouterPermission(route.routeConfig.path);
  }
}

<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2">
      <b>Заявки на добавление произвольного товара</b>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img
          src="../../../../../../assets/img/icon/close/Icon_Close.svg"
          alt="close"
        />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content *ngIf="loadRequestList$ | async as loadRequestList">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Заказ</th>
        <th>Наименование</th>
        <th>Каталожный</th>
        <th>Бренд</th>
        <th>Статус</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of loadRequestList">
        <td>{{ item.orderNumber }}</td>
        <td>{{ item.nomen }}</td>
        <td>{{ item.katalNumber }}</td>
        <td>{{ item.brand }}</td>
        <td>{{ item.action }}</td>
        <td>
          <button class="btn btn-success" mat-button (click)="showCard(item)">
            Добавить
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</mat-card-content>

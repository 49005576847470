<div *ngIf="loadDicInfo$ | async as loadDicInfo">
  <button
    mat-button
    [matMenuTriggerFor]="catalog"
    matTooltip="Каталоги для подбора"
    matTooltipClass="tooltip"
    class="btn-block"
    style="margin-top: 0.75rem"
    (click)="stopLoad($event)"
  >
    <mat-icon class="d-none d-md-block">fact_check</mat-icon>
    <div class="d-block d-md-none">Схемы подбора</div>
  </button>
  <mat-menu #catalog="matMenu" style="width: 280px !important">
    <button mat-menu-item [matMenuTriggerFor]="parts">Схемы</button>
    <button mat-menu-item [matMenuTriggerFor]="brands">Бренды</button>
    <button mat-menu-item [matMenuTriggerFor]="shield">Защиты</button>
  </mat-menu>
  <mat-menu #parts="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="ourCatalog">VAG</button>
    <a
      *ngFor="let item of loadDicInfo.dicSelect"
      [href]="item.value"
      target="_blank"
      mat-menu-item
      >{{ item.data }}</a
    >
  </mat-menu>

  <mat-menu #ourCatalog="matMenu">
    <a
      *ngFor="let item of loadDicInfo.dicSelectSelf"
      [href]="item.value"
      target="_blank"
      mat-menu-item
      >{{ item.data }}</a
    >
  </mat-menu>

  <mat-menu #brands="matMenu">
    <a
      *ngFor="let item of loadDicInfo.brandCatalog"
      [href]="item.value"
      target="_blank"
      mat-menu-item
    >
      {{ item.data }}
    </a>
  </mat-menu>
  <mat-menu #shield="matMenu">
    <div
      *ngFor="let item of loadDicInfo.shieldMark"
      (click)="onLoadShieldInfo(item.value)"
      mat-menu-item
    >
      {{ item.data }}
    </div>
  </mat-menu>
</div>

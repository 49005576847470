import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disable-block',
  templateUrl: './disable-block.component.html',
  styleUrls: ['./disable-block.component.scss']
})
export class DisableBlockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

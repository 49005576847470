import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ClsService } from './service/cls.service';
import { SelectItem } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CallListCreateOrderComponent } from './call-list-create-order/call-list-create-order.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-call-list',
  templateUrl: './call-list.component.html',
  styleUrls: ['./call-list.component.scss'],
  providers: [ClsService],
})
export class CallListComponent implements OnInit, OnDestroy {
  form: FormGroup;
  vm$: Observable<any>;
  loading: boolean;
  statuses: SelectItem[];
  private destroy = new Subject<any>();
  showCreateOrder: number[];
  trigger$ = new Subject();
  loadOrderListOpt: [{ label: string; value: number }];

  constructor(
    private cls: ClsService,
    public srv: SrvService,
    private title: Title,
    private dialog: MatDialog
  ) {
    this.form = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
    });

    this.title.setTitle('Журнал звонков');
  }

  loadOrderList$: Observable<
    [{ label: string; value: number }]
  > = this.trigger$.pipe(
    switchMap((val: string) =>
      this.cls
        .loadOrderClientList(val)
        .pipe(tap((val2) => (this.loadOrderListOpt = val2)))
    )
  );

  ngOnInit(): void {
    this.statuses = [
      { label: 'Пропущеный', value: '1' },
      // { label: 'Отвечен', value: '2' },
      { label: 'Отвечен в вайбер', value: '3' },
    ];
    // setInterval(() => { this.getCallListInfo(); }, 15000);

    this.srv.userPerm.pipe(filter((val) => val != null)).subscribe((value) => {
      this.showCreateOrder = value.permission.filter((val) => +val === 44);
    });
  }

  getCallListInfo(): void {
    this.loading = true;
    const formVal = this.form.value;
    this.vm$ = this.cls
      .getCallList(
        this.srv.convertDate(formVal.start),
        this.srv.convertDate(formVal.end)
      )
      .pipe(finalize(() => (this.loading = false)));
  }

  changeStatus(value, rowData): void {
    switch (+value) {
      case 1:
        rowData.actionName = 'Пропущенный';
        break;
      case 2:
        rowData.actionName = 'Отвечен';
        break;
      case 3:
        rowData.actionName = 'В вайбер';
        break;
    }
    rowData.action = value;

    this.cls
      .setCallStatus(rowData.id, value)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.srv.successMessage('Данные сохранены');
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  onCreateNewOrderToUser(hash): void {
    this.dialog.open(CallListCreateOrderComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      data: {
        hash,
      },
    });
  }

  setOrder(value, rowData: any): void {
    this.cls
      .setOrder(rowData.id, value)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.srv.successMessage('Данные сохранены');
      });
  }

  loadOrderList(rowData: any): void {
    this.trigger$.next(rowData.clientId);
  }
}

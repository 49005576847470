<script src="https://img.catalogs-parts.com/js/frame.js"></script>
<div class="row" *ngIf="modelLink">
  <iframe
    id="cp_frame"
    name="cp_frame"
    [src]="modelLink | safe"
    onload="FrameManager.registerFrame(this)"
    frameborder="0"
    marginheight="0"
    scrolling="no"
    marginwidth="0"
    width="100%"
    height="20000"
  ></iframe>
</div>

<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>Добавить банковскую карту.</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<mat-card-content>
  <div class="row">
    <div class="col">
      <form [formGroup]="formPayment">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Номер карты</mat-label>
          <input matInput
                 type="text"
                 [mask]="'0000-0000-0000-9999'"
                 formControlName="number"
          />
          <mat-error *ngIf="formPayment.get('number').hasError('required')">
            Введите номер банковской карты
          </mat-error>
          <mat-error *ngIf="formPayment.get('number').hasError('minlength')">
            Номер карты некорректный
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Владелец карты</mat-label>
          <input matInput
                 type="text"
                 formControlName="name"
          />
        </mat-form-field>
      </form>
    </div>
  </div>
</mat-card-content>
<mat-dialog-actions align="end">
  <button mat-button class="btn btn-danger" type="button" matDialogClose>Отмена</button>
  <button mat-button class="btn btn-success" type="button" (click)="addNewPayment()">Сохранить</button>
</mat-dialog-actions>

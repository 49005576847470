<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2">
      <b>Полная применяемость</b>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <table
    mat-table
    matSort
    [dataSource]="sortListCar"
    class="table dense table-striped table-hover"
  >
    <!-- ID Column -->
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef class="text-center">Модель</th>
      <td mat-cell *matCellDef="let row">{{ row.model }} {{ row.body }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="mod">
      <th mat-header-cell *matHeaderCellDef>Модификация</th>
      <td mat-cell *matCellDef="let row">
        {{
          row.nameMod +
            " (" +
            row.hp +
            " л.с.)" +
            row.engineCode +
            " " +
            row.fuel
        }}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="years">
      <th mat-header-cell *matHeaderCellDef class="text-center">Год выпуска</th>
      <td mat-cell *matCellDef="let row" class="text-center">
        <button mat-button class="btn">
          {{ row.dateStart }} -
          {{ row.dateEnd == "2100" ? (nowDate | date: "yyyy") : row.dateEnd }}
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card-content>

import { Component, Inject, OnInit } from '@angular/core';
import { RequestBody } from '../../../../core/interfaces/interfaces';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../../../core/services/api.service';
import { Observable, Subject } from 'rxjs';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-dialog-product-on-stock',
  templateUrl: './dialog-product-on-stock.component.html',
  styleUrls: ['./dialog-product-on-stock.component.scss'],
})
export class DialogProductOnStockComponent implements OnInit {
  vm$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private api: ApiService,
    private srv: SrvService
  ) {}

  ngOnInit(): void {
    this.onLoadMoreInfo();
  }

  onLoadMoreInfo(): void {
    const dialogInfo = this.dialogData;

    const startDate = this.srv.convertDate(dialogInfo.startDate);
    const endDate = this.srv.convertDate(dialogInfo.endDate);

    const q: RequestBody = {
      modelName: 'AnalyticsReturn',
      calledMethod: 'getReturnProductOnStock',
      methodProperties: {
        type: 1,
        startDate,
        endDate,
      },
    };

    this.vm$ = this.api.post_jwt(q);
  }
}

import { Injectable } from '@angular/core';
import {ApiService} from '../../../core/services/api.service';
import {RequestBody} from '../../../core/interfaces/interfaces';
import {Observable} from 'rxjs';

@Injectable()
export class ProdProfitService {

  constructor(
    private api: ApiService
  ) { }

  getProdInfo(): Observable<any>{
    const q: RequestBody = {
      modelName: 'AnalyticsProd',
      calledMethod: 'getProdInfo',
      methodProperties: {
        type: 1,
      }
    };
    return this.api.post_jwt(q);
  }

}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ChatService } from '../core/chat.service';
import { ProdInfo } from '../core/chat';
import { OtherSpr } from '@core/interfaces/interfaces';
import { SrvService } from 'repositories';

@Component({
  selector: 'lib-order-chat',
  templateUrl: './order-chat.component.html',
  providers: [ChatService],
})
export class OrderChatComponent implements OnInit, OnDestroy {
  destroy = new ReplaySubject();
  warehouseList$: Observable<OtherSpr[]>;
  form: FormGroup;
  countList = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
  ];
  prodInfo$: Observable<ProdInfo>;
  prodInfoReq: any;
  shield =
    'Марка\n' +
    'Модель\n' +
    'Год выпуска\n' +
    'Объем двигателя\n' +
    'Тип топлива\n' +
    'Коробка\n' +
    'Webasto';

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private chat: ChatService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<OrderChatComponent>
  ) {
    this.form = new FormGroup({
      reqCat: new FormControl(1, [Validators.required]),
      comment: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.loadProdInfo();
  }

  loadProdInfo(): void {
    this.prodInfo$ = this.chat
      .loadProdInfo(this.dialogData.id, this.dialogData.resNumber)
      .pipe(
        tap((val) => {
          this.prodInfoReq = val;

          if (+this.prodInfoReq.codeProvider === 340) {
            this.form.controls.comment.setValue(this.shield);
          }
        })
      );
  }

  onCreateNewRequest(): any {
    if (this.form.invalid) {
      this.srv.errorMessage('Не все поля заполнены');

      return false;
    }

    const obj = {
      typeRequest: 1,
      category: this.form.value.reqCat,
      title: this.prodInfoReq.katalNumber + ' ' + this.prodInfoReq.brand,
      productId: this.dialogData.id,
      text_1: this.prodInfoReq.codeProvider,
      text_2: this.prodInfoReq.count,
      text_3: this.srv.convertDateTime(
        this.prodInfoReq.planDateSend + ' ' + this.prodInfoReq.planDateSendTime
      ),
      comment: this.form.value.comment,
    };

    this.chat
      .onCreateNewRequestOrder(obj)
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        if (+value.code === 200) {
          this.dialogRef.close({ code: 200 });
          this.srv.successMessage('Запрос создан');
        }
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  setComment(): void {
    switch (this.form.value.reqCat) {
      case 2:
        this.form.controls.comment.setValidators([Validators.required]);
        this.form.controls.comment.updateValueAndValidity();
        break;
      default:
        this.form.controls.comment.clearValidators();
        this.form.controls.comment.updateValueAndValidity();

        break;
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRequest, BaseApiDirective } from 'repositories';
@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseApiDirective {
  setSubmitNtf(id: number): Observable<any> {
    return this.postRequest(
      ApiRequest.notification.name,
      ApiRequest.notification.method.setSubmitNtf,
      {
        type: 1,
        id,
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { SharedProviderCountTableComponent } from './shared-provider-count-table.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SharedProviderCountTableComponent],
  imports: [MatIconModule, MatTooltipModule, CommonModule],
  exports: [SharedProviderCountTableComponent],
})
export class SharedProviderCountTableModule {}

<div class="toast toast-{{ toast.type }}" [style.bottom.px]="i * 130">
  <small>
    <b>{{ toast.date | date: "dd-MM-YYYY HH:mm" }}</b>
  </small>
  <div class="my-1">
    <b>{{ toast.title }}</b>
  </div>
  <div [innerHTML]="toast.text"></div>

  <a
    class="btn btn-success btn-small close"
    mat-button
    (click)="remove.emit(i); setSubmitNtf(id)"
    matTooltip="Ознакомлен"
    matTooltipClass="tooltip"
  >
    <mat-icon>check</mat-icon>
  </a>
  <a
    class="btn btn-warning btn-small funk"
    mat-button
    *ngIf="+btnFunk > 0"
    (click)="remove.emit(i); setFunkBtn(btnFunk, hash); setSubmitNtf(id)"
    matTooltip="Открыть"
    matTooltipClass="tooltip"
  >
    <mat-icon>insert_link</mat-icon>
  </a>
</div>

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { ChatItem, ChatList } from '../core/chat';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ChatService } from '../core/chat.service';
import { WarehouseChatResponseComponent } from '../warehouse-chat-response/warehouse-chat-response.component';
import { WarehouseChatComponent } from '../warehouse-chat/warehouse-chat.component';
import { takeUntil } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Component({
  selector: 'lib-warehouse-chat-list',
  templateUrl: './warehouse-chat-list.component.html',
  providers: [ChatService],
})
export class WarehouseChatListComponent implements OnInit, OnDestroy {
  requestList$: Observable<ChatList[]>;
  requestListArchive$: Observable<ChatList[]>;

  destroy: ReplaySubject<any> = new ReplaySubject<any>();

  constructor(
    private dialog: MatDialog,
    private srv: SrvService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private chat: ChatService
  ) {}

  ngOnInit(): void {
    this.loadRequestList();
  }

  loadRequestList(): void {
    this.requestList$ = this.chat.loadRequestList(this.dialogData.id);
    this.requestListArchive$ = this.chat.loadRequestListArchive(
      this.dialogData.id
    );
  }

  setResponse(num: number, item: ChatItem): void {
    const dialog = this.dialog.open(WarehouseChatResponseComponent, {
      data: {
        title: item.title,
        count: item.text_2,
        warehouse: item.text_1,
        date: item.text_3,
        dateEnd: item.dateEnd,
        category: item.category,
        comment: item.comment,
        num,
        resNumber: item.number,
      },
      maxHeight: '94vh',
      minWidth: '50vw',
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (+val.code === 200) {
          this.loadRequestList();
        }
      });
  }

  onCreateNewRequestWarehouse(): void {
    const dialog = this.dialog.open(WarehouseChatComponent, {
      data: {
        id: this.dialogData.id,
        katalNumber: this.dialogData.katalNumber,
        brand: this.dialogData.brand,
      },
      maxHeight: '94vh',
      minWidth: '50vw',
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (+val.code === 200) {
          this.loadRequestList();
        }
      });
  }

  closeRequest(num: number): void {
    this.chat
      .closeRequest(num)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.loadRequestList();

        this.srv.successMessage('Запрос перемещен в архив');
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}

import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {RequestBody} from '../../../../core/interfaces/interfaces';
import {ApiService} from '../../../../core/services/api.service';

@Component({
  selector: 'app-dialog-summary',
  templateUrl: './dialog-summary.component.html',
  styleUrls: ['./dialog-summary.component.scss']
})
export class DialogSummaryComponent implements OnInit {
  loading: any;
  loadSummary$: Observable<any>;

  constructor(
    private api: ApiService
  ) {
    this.loadSummary();
  }

  ngOnInit(): void {
  }

  loadSummary(): void {
    const q: RequestBody = {
      modelName: 'AnalyticsCount',
      calledMethod: 'getSummaryLoad',
      methodProperties: {
        type: 1,
      }
    };

    this.loadSummary$ = this.api.post_jwt(q);

  }

}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-warehouse-info',
  templateUrl: './dialog-warehouse-info.component.html',
  styleUrls: ['./dialog-warehouse-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWarehouseInfoComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataObj: any // Инжектирование Переменной для приема данных из вне, Объект
  ) {}

  ngOnInit(): void {}
}

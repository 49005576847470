import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RrplService } from '../core/rrpl.service';
import { RandomRequestApproveComponent } from '../../random-request-approve/random-request-approve/random-request-approve.component';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-random-request-payment-list',
  templateUrl: './random-request-payment-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RrplService],
})
export class RandomRequestPaymentListComponent implements OnInit, OnDestroy {
  trigger$ = new Subject();
  destroy$ = new ReplaySubject();

  loadRequestList$: Observable<any> = this.trigger$.pipe(
    startWith(1),
    switchMap((val) => this.rrpl.loadRequestOrderList())
  );

  constructor(
    private rrpl: RrplService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {}

  showCard(item): void {
    const dialogRef = this.dialog.open(RandomRequestApproveComponent, {
      data: {
        item,
      },
      maxHeight: '94vh',
      minWidth: '40vw',
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.trigger$.next());
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UclService } from './core/ucl.service';
import { UclSmallCard } from './core/ucl';
import { FormControl, FormGroup } from '@angular/forms';
import { OtherSpr } from '../../../core/interfaces/interfaces';

@Component({
  selector: 'app-user-card-library',
  templateUrl: './user-card-library.component.html',
  styleUrls: ['./user-card-library.component.scss'],
  providers: [UclService],
})
export class UserCardLibraryComponent implements OnInit {
  userCardList$: Observable<UclSmallCard[]>;
  actionUserList$: Observable<OtherSpr[]>;
  typeUserList$: Observable<OtherSpr[]>;
  formFilter: FormGroup;

  constructor(private ucl: UclService) {
    this.formFilter = new FormGroup({
      phone: new FormControl(),
      name: new FormControl(),
      action: new FormControl(),
      type: new FormControl(),
      date: new FormControl(),
      start: new FormControl(0),
      limit: new FormControl(1000),
    });
  }

  ngOnInit(): void {
    this.loadSpr();
    this.loadUserCardList();
  }

  loadSpr(): void {
    this.actionUserList$ = this.ucl.loadActionUserList();
    this.typeUserList$ = this.ucl.loadTypeUserList();
  }

  loadUserCardList(): void {
    const q = {
      phone: this.formFilter.value.phone,
      name: this.formFilter.value.name,
      action: this.formFilter.value.action,
      typeUser: this.formFilter.value.type,
      start: this.formFilter.value.start,
      limit: this.formFilter.value.limit,
    };

    this.userCardList$ = this.ucl.loadUserCardList(q);
  }
}

<p-table
  *ngIf="vm$ | async as vm"
  [columns]="vm.headerList"
  [value]="vm.orderList"
  [rowsPerPageOptions]="[10, 25, 50, 100, 500]"
  [paginator]="true"
  currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
  [filterDelay]="0"
  autoLayout="true"
  [rows]="10"
  selectionMode="single"
  styleClass="p-datatable-gridlines p-datatable-sm"
>
  <ng-template pTemplate="caption">
    Товары возвращенные на склад за выбранный диапазон с
    {{ dialogData.startDate | date: "dd.MM.yyyy" }} по
    {{ dialogData.endDate | date: "dd.MM.yyyy" }}
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        style="font-weight: 400; font-size: 12px"
      >
        <p-sortIcon [field]="col.field"></p-sortIcon>
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr [class]="rowData['class']">
      <td *ngFor="let col of columns" style="font-weight: 300; font-size: 12px">
        <span [ngSwitch]="col.field">
          <span *ngSwitchCase="'dateBid'">
            {{ rowData[col.field] | date: "dd.MM.yyyy HH:mm" }}
          </span>
          <span *ngSwitchCase="'dateToProvider'">
            {{ rowData[col.field] | date: "dd.MM.yyyy HH:mm" }}
          </span>
          <span *ngSwitchCase="'zakaz'">
            <a
              [href]="'/crm/order-card/' + rowData[col.field]"
              target="_blank"
              >{{ rowData[col.field] }}</a
            >
          </span>
          <span *ngSwitchDefault>
            {{ rowData[col.field] }}
          </span>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

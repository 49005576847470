import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {SudService} from '../sud.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-load-more-order-info',
  templateUrl: './dialog-load-more-order-info.component.html',
  styleUrls: ['./dialog-load-more-order-info.component.scss'],
  providers: [SudService]

})
export class DialogLoadMoreOrderInfoComponent implements OnInit {

  list$: Observable<any>;

  constructor(
    private sud: SudService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
  }

  ngOnInit(): void {
    this.onloadOrderInfo();
  }

  onloadOrderInfo(): void {
    this.list$ = this.sud.onloadOrderInfo(
      this.dialogData.startDate,
      this.dialogData.endDate,
      this.dialogData.suId,
      this.dialogData.filter,
      this.dialogData.typeFilter
    );
  }
}

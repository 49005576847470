import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../src/environments/environment';

interface RequestBody {
  token?: string; // Ключ
  modelName: string; // Название Класса
  workmanId?: string; // ID Сотрудника
  calledMethod: string; // Вызываемый метод
  methodProperties: object; // Тело запроса
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) {}

  post(data: RequestBody): Observable<any> {
    return this.http.post(environment.url, data);
  }

  put(data: RequestBody): Observable<any> {
    return this.http.put(environment.url, data);
  }

  post_jwt(data: RequestBody): Observable<any> {
    return this.http.post(environment.url_jwt, data);
  }

  uploadFileProductCard(data: FormData): Observable<any> {
    return this.http.post(environment.api_jwt_file_product_card, data);
  }

  private getParams(
    modelName: string,
    calledMethod: string,
    methodProperties: { [key: string]: any }
  ): HttpParams {
    return new HttpParams()
      .set('modelName', modelName)
      .set('calledMethod', calledMethod)
      .set('methodProperties', JSON.stringify(methodProperties));
  }

  getSite({
    modelName,
    calledMethod,
    methodProperties,
  }: RequestBody): Observable<any> {
    const params = this.getParams(modelName, calledMethod, methodProperties);

    return this.http.get(environment.urlGetSite, { params });
  }
}

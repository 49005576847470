import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchService } from '../core/search.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SpecInfoDialogComponent } from '../dialog/spec-info-dialog/spec-info-dialog.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-form-add-to-order',
  templateUrl: './form-add-to-order.component.html',
  styleUrls: ['./form-add-to-order.component.scss'],
  providers: [SearchService],
})
export class FormAddToOrderComponent implements OnInit, OnDestroy {
  @Input() orderList; // список заказов
  @Input() setOe; // признак вхождение оригинального номера
  @Input() oeNum; // Оригинальный номер из поиска
  @Input() id; // id  из прайса
  @Input() katalNumber; // номер товара
  @Input() brand; // бренд
  @Input() specInfo; // специнформацией

  destroy$ = new ReplaySubject();
  countList = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    40,
  ];

  form: FormGroup;

  constructor(
    private srv: SrvService,
    private search: SearchService,
    private dialog: MatDialog
  ) {
    this.form = new FormGroup({
      oeNumber: new FormControl('', [Validators.required]),
      order: new FormControl('', [Validators.required]),
      count: new FormControl(1, [Validators.required]),
      selfCheck: new FormControl(),
      class: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.form.controls.selfCheck.setValue(+this.setOe === 1);
    this.form.controls.oeNumber.setValue(this.oeNum);
    this.searchOeNum();
  }

  setCheck(): void {
    if (this.form.value.selfCheck) {
      this.form.controls.oeNumber.clearValidators();
      this.form.controls.oeNumber.updateValueAndValidity();
    } else {
      this.form.controls.oeNumber.setValidators([Validators.required]);
      this.form.controls.oeNumber.updateValueAndValidity();
    }
  }

  onAddProduct(): any {
    if (this.specInfo) {
      const dialog = this.dialog.open(SpecInfoDialogComponent, {
        data: {
          info: this.specInfo,
        },
      });

      dialog
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          if (value) {
            this.sendProd();
          }
        });
    } else {
      this.sendProd();
    }
  }

  sendProd(): boolean {
    if (this.form.invalid) {
      this.srv.errorMessage('Заполнены не все поля');
      return false;
    }

    const obj = {
      oeNumber: this.form.value.oeNumber,
      order: this.form.value.order,
      selfCheck: this.form.value.selfCheck,
      class: this.form.value.class,
      count: this.form.value.count,
      id: this.id,
    };

    this.search
      .onAddProduct(obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((val: { hubTree: string }) => {
        this.form.controls.order.setValue('');

        let message = '';
        if (val) {
          message = `Возможна узловая допродажа ${val.hubTree}`;
        }

        this.srv.primaryMessage('Товар добавлен \n\n' + message, 10000);
      });
  }

  searchOeNum(): void {
    const obj = {
      katalNumber: this.katalNumber,
      brand: this.brand,
      oe: this.form.value.oeNumber,
    };

    // Поиск ОЕ номера
    this.search
      .searchOENum(obj)
      .pipe(distinctUntilChanged(), debounceTime(200))
      .subscribe((val) => {
        this.form.controls.selfCheck.setValue(+val.setOe === 3);
        this.form.controls.class.setValue(val.class);
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HeaderService } from '../../core/header.service';

@Component({
  selector: 'lib-header-shield-info',
  templateUrl: './header-shield-info.component.html',
})
export class HeaderShieldInfoComponent implements OnInit {
  title: any;
  loadProtectionList$: Observable<any> = this.hs.loadMarkProtection({
    mark: this.dialogData.mark,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: { mark: string },
    private hs: HeaderService
  ) {
    this.title = 'Подобрать защиту';
  }

  ngOnInit(): void {}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { CpServiceService } from './service/cp-service.service';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-client-profit',
  templateUrl: './client-profit.component.html',
  styleUrls: ['./client-profit.component.scss'],
  providers: [CpServiceService],
})
export class ClientProfitComponent implements OnInit {
  form: FormGroup;
  vm$: Observable<any>;
  clientList$: Observable<any>;
  loadingClientTable: boolean;

  // для выбора всего списка соответсвенно
  @ViewChild('allSelectedForm') private allSelectedForm: MatOption;
  @ViewChild('allSelectedCar') private allSelectedCar: MatOption;
  @ViewChild('allSelectedSource') private allSelectedSource: MatOption;

  constructor(private cp: CpServiceService) {
    this.form = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
      source: new FormControl(''),
      car: new FormControl(''),
      formSale: new FormControl(''),
      typeFilter: new FormControl('1'),
    });
  }

  ngOnInit(): void {
    this.getDynamicSpr();
  }

  // Обновление динамических справочников
  getDynamicSpr(): void {
    /*    const formVal = this.form.value;
        this.vm$ = forkJoin({
          // Справочник Источников
          sourceList: this.cp.getSourceList(formVal.start, formVal.end),
          // Справочник Автомобилей
          carList: this.cp.getCarList(formVal.start, formVal.end),
          // Справочник Способов обращений
          formList: this.cp.getFormList(formVal.start, formVal.end),
        });*/
  }

  /*


    // функции выбрать все
    toggleAllForm(): void {
      if (this.allSelectedForm.selected) {
        this.form.controls.formSale
          .patchValue([...this.formList.map(item => item.value), 'all']);
      } else {
        this.form.controls.formSale.patchValue([]);
      }
    }

    toggleAllCar(): void {
      if (this.allSelectedCar.selected) {
        this.form.controls.car
          .patchValue([...this.carList.map(item => item.value), 'all']);
      } else {
        this.form.controls.car.patchValue([]);
      }
    }

    toggleAllSource(): void {
      if (this.allSelectedSource.selected) {
        this.form.controls.source
          .patchValue([...this.sourceList.map(item => item.value), 'all']);
      } else {
        this.form.controls.source.patchValue([]);
      }
    }
  */

  getOrderInfo(): void {
    const formVal = this.form.value;
    this.clientList$ = this.cp
      .getClientList({
        dateStart: formVal.start,
        dateEnd: formVal.end,
      })
      .pipe(
        tap(() => (this.loadingClientTable = true)),
        map((data) => data),
        finalize(() => (this.loadingClientTable = false))
      );
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UclService } from '../../core/ucl.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-uc-dialog-add-new-recipient',
  templateUrl: './uc-dialog-add-new-recipient.component.html',
  styleUrls: ['./uc-dialog-add-new-recipient.component.scss'],
  providers: [UclService],
})
export class UcDialogAddNewRecipientComponent implements OnInit {
  formRecipient: FormGroup;

  constructor(
    private ucl: UclService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<UcDialogAddNewRecipientComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.formRecipient = new FormGroup({
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
      name: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      middleName: new FormControl(),
    });
  }

  ngOnInit(): void {}

  addNewPayment(): any {
    if (this.formRecipient.invalid) {
      this.srv.errorMessage('Форма заполнена не верно');
      return false;
    }

    const obj = {
      hash: this.dialogData.hash,
      lastName: this.formRecipient.value.lastName,
      userName: this.formRecipient.value.name,
      otchestvo: this.formRecipient.value.middleName,
      phone: this.formRecipient.value.phone,
    };

    this.ucl.addNewRecipient(obj).subscribe(() => {
      this.srv.successMessage('Получатель сохранен');
      this.dialogRef.close({ result: true });
    });
  }
}

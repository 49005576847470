import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTaskIndicatorListComponent } from './header-task-indicator-list.component';
import { TitleModalWindowModule } from '../../shared/title-modal-window/title-modal-window.module';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { CountTableInfoModule } from '../../shared/count-table-info/count-table-info.module';
import { HeaderRandomProductModule } from '../header-random-product/header-random-product.module';

@NgModule({
  declarations: [HeaderTaskIndicatorListComponent],
  imports: [
    CommonModule,
    TitleModalWindowModule,
    MatCardModule,
    MatTabsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CountTableInfoModule,
    HeaderRandomProductModule,
  ],
})
export class HeaderTaskIndicatorListModule {}

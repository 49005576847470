<mat-form-field style="width: 100%">
  <mat-label>Поиск</mat-label>
  <input
    matInput
    [formControl]="search"
    autocomplete="off"
    (keyup.enter)="setSearchProduct()"
    (click)="stopLoad($event)"
  />
  <button
    mat-icon-button
    class="btn btn-round-sm"
    (click)="setSearchProduct()"
    matSuffix
  >
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>

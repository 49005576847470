<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto align-self-center">Сохранить ТТН</div>
    <div class="col-auto">

      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<mat-form-field style="width: 100%" appearance="outline">
  <mat-label>
    Номер ТТН
  </mat-label>
  <input matInput [formControl]="ttn">
</mat-form-field>
<br>
<mat-form-field
  appearance="outline"
  class="w-100"
  *ngIf="unitList$ | async as unitList"
  (click)="$event.stopPropagation()"

>
  <mat-label>Структурная единица</mat-label>
  <mat-select [formControl]="unitId">
    <mat-option *ngFor="let item of unitList" [value]="+item.value">
      {{ item.text }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-dialog-actions align="end">
  <button type="button" (click)="saveTtn()" mat-button class="btn btn-success">Сохранить</button>
</mat-dialog-actions>

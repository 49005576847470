import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UclService } from '../../core/ucl.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-uc-dialog-edit-recipient',
  templateUrl: './uc-dialog-edit-recipient.component.html',
  styleUrls: ['./uc-dialog-edit-recipient.component.scss'],
  providers: [UclService],
})
export class UcDialogEditRecipientComponent implements OnInit {
  formRecipient: FormGroup;

  constructor(
    private ucl: UclService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<UcDialogEditRecipientComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.formRecipient = new FormGroup({
      phone: new FormControl(this.dialogData.phone, [
        Validators.required,
        Validators.minLength(10),
      ]),
      name: new FormControl(this.dialogData.name, [Validators.required]),
      lastName: new FormControl(this.dialogData.lastName, [
        Validators.required,
      ]),
      middleName: new FormControl(this.dialogData.middleName),
    });
  }

  ngOnInit(): void {}

  editRecipient(): any {
    if (this.formRecipient.invalid) {
      this.srv.errorMessage('Форма заполнена не верно');
      return false;
    }

    const obj = {
      hash: this.dialogData.hash,
      id: this.dialogData.id,
      lastName: this.formRecipient.value.lastName,
      userName: this.formRecipient.value.name,
      otchestvo: this.formRecipient.value.middleName,
      phone: this.formRecipient.value.phone,
    };

    this.ucl.editRecipient(obj).subscribe(() => {
      this.srv.successMessage('Изменения сохранены');
      this.dialogRef.close({ result: true });
    });
  }
}

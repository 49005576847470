import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { PretensionInfo, TaskComment, TaskInfo } from '../core/task';
import { OtherSpr } from '../../../../core/interfaces/interfaces';
import { TaskService } from '../core/task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { TaskDialogChangeTaskComponent } from '../task-dialog-change-task/task-dialog-change-task.component';
import { DialogYesNoComponent } from '../../../../shared/dialog-yes-no/dialog-yes-no.component';
import { Title } from '@angular/platform-browser';
import { TaskDialogChangeManagerComponent } from '../task-dialog-change-manager/task-dialog-change-manager.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-pretension',
  templateUrl: './pretension.component.html',
  styleUrls: ['./pretension.component.scss'],
  providers: [TaskService],
})
export class PretensionComponent implements OnInit, OnDestroy {
  form: FormGroup;
  formDecision: FormGroup;
  pretensionId: number;
  orderNumber: number;
  pretensionInfo$: Observable<PretensionInfo>;
  preInfo: PretensionInfo;
  taskComment$: Observable<TaskComment[]>;
  categoryList$: Observable<OtherSpr[]>;
  altOrderList$: Observable<OtherSpr[]>;
  altProductList$: Observable<any>;
  subCategoryList: OtherSpr[];
  bankCard: OtherSpr[];
  destroy = new Subject();

  dateBegin: FormControl;
  dateEnd: FormControl;
  category: FormControl;
  subCategory: FormControl;
  comment: FormControl;
  manager: FormControl;
  productList: any;
  showEditManager: number[]; // показать кнопку изменить исполнителя
  workmanSelfId: number;

  constructor(
    private task: TaskService,
    private activateRoute: ActivatedRoute,
    private route: Router,
    private srv: SrvService,
    private dialog: MatDialog,
    private title: Title
  ) {
    this.pretensionId = this.activateRoute.snapshot.params.id;

    this.srv.userPerm.pipe(filter((val) => val != null)).subscribe((value) => {
      this.showEditManager = value.permission.filter((val) => +val === 43);
      this.workmanSelfId = +value.workmanId;
    });

    this.title.setTitle('Претензия № ' + this.pretensionId);

    this.dateBegin = new FormControl('');
    this.dateEnd = new FormControl('');
    this.category = new FormControl('');
    this.subCategory = new FormControl('');
    this.comment = new FormControl('');
    this.manager = new FormControl('');

    this.form = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });

    this.formDecision = new FormGroup({
      product: new FormControl(1, [Validators.required, Validators.min(1)]),
      payment: new FormControl('', [Validators.required, Validators.min(1)]),
      alt: new FormControl('', [Validators.required]),
      katalNumber: new FormControl(''),
      orderNumber: new FormControl(''),
      brand: new FormControl(''),
      katalNumberCross: new FormControl(''),
      brandCross: new FormControl(''),
      card: new FormControl(''),
      altOrder: new FormControl(''),
      altProd: new FormControl(''),
      cardReq: new FormControl(false),
      sendSms: new FormControl(true),
      returnTtn: new FormControl('', [Validators.required]),
      returnTtnReq: new FormControl(false),
      comment: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.loadPretensionInfo();

    this.loadReason();
  }

  // загрузить информацию по задаче
  loadPretensionInfo(): void {
    this.pretensionInfo$ = this.task.loadPretensionInfo(this.pretensionId).pipe(
      tap((val) => {
        this.preInfo = val;
        this.dateBegin.setValue(this.srv.convertDateTimeInput(val.dateStart));
        this.dateEnd.setValue(this.srv.convertDateTimeInput(val.dateEnd));
        this.category.setValue(+val.category);
        this.manager.setValue(val.manager);
        this.subCategory.setValue(val.subReason);
        this.comment.setValue(val.getCommentLastWorkComment);
        this.productList = val.productList;
        this.bankCard = val.bankCard;
        this.orderNumber = val.orderNumber;
        this.getReturnReason();
        this.formDecision
          .get('product')
          .setValue(val.whatProduct ? +val.whatProduct : 1);
        this.formDecision
          .get('payment')
          .setValue(val.whatProduct ? +val.whatPayment : 1);
        this.formDecision.get('card').setValue(val.card);
        this.formDecision.get('comment').setValue(val.comment);
        this.formDecision.get('sendSms').setValue(val.sendSms);
        this.formDecision.get('alt').setValue(+val.altChange);
        this.formDecision.get('altOrder').setValue(val.orderAlt);
        this.formDecision.get('returnTtn').setValue(val.returnTtn);

        if (+val.altChange === 1) {
          this.loadAltOrder();
        }
      })
    );
  }

  // загрузить комментарии по задаче
  loadTaskComment(): void {
    this.taskComment$ = this.task.loadTaskComment(this.pretensionId);
  }

  // загрузить категории
  loadReason(): void {
    this.categoryList$ = this.task.loadReason();
  }

  // Загрузить заказы
  loadAltOrder(): void {
    this.altOrderList$ = this.task.loadAltOrder(this.preInfo.hash);
  }

  // Загрузить товары
  loadAltProduct(): void {
    this.altProductList$ = this.task.loadAltProduct(
      this.formDecision.value.altOrder
    );
  }

  // Получить список подпричин на позврат
  getReturnReason(): void {
    this.task
      .getReturnReason(this.category.value)
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (val) {
          this.subCategoryList = val;
          //  this.form.controls.subReason.setValidators([Validators.required]);
          //  this.form.controls.subReason.updateValueAndValidity();
        } else {
          //  this.form.controls.subReason.clearValidators();
          //  this.form.controls.subReason.updateValueAndValidity();
        }
      });
  }

  // взять задачу в работу
  setPretensionToWork(): void {
    this.task
      .setTaskToWork(this.pretensionId)
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (+val.code === 200) {
          this.loadPretensionInfo();
          this.srv.successMessage('Претензия взята в работу');
        } else {
          this.srv.errorMessage('Ошибка обратитесь к администратору');
        }
      });
  }

  // Сохранить тданные по проверке
  onSaveChecked(): void {
    const obj = {
      id: this.pretensionId,
      product: this.productList,
    };

    this.task
      .onSaveChecked(obj)
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (+val.code === 200) {
          this.loadPretensionInfo();
          this.srv.successMessage('Данные по проверке сохранены');
        } else {
          this.srv.errorMessage('Ошибка обратитесь к администратору');
        }
      });
  }

  // Уточнить возвратнут ТТН позже
  notReqReturnTtn(): void {
    if (!this.formDecision.value.returnTtnReq) {
      this.formDecision.controls.returnTtn.setValidators([Validators.required]);
      this.formDecision.controls.returnTtn.updateValueAndValidity();
    } else {
      this.formDecision.controls.returnTtn.clearValidators();
      this.formDecision.controls.returnTtn.updateValueAndValidity();
    }
  }

  // Уточнить банковскую карту позже
  notReqCard(): void {
    if (!this.formDecision.value.cardReq) {
      this.formDecision.controls.card.setValidators([Validators.required]);
      this.formDecision.controls.card.updateValueAndValidity();
    } else {
      this.formDecision.controls.card.clearValidators();
      this.formDecision.controls.card.updateValueAndValidity();
    }
  }

  // Выбор альтернативы
  changeAlt(): void {
    if (+this.formDecision.value.alt === 2) {
      this.formDecision.controls.comment.setValidators([Validators.required]);
      this.formDecision.controls.comment.updateValueAndValidity();
      this.formDecision.controls.altOrder.clearValidators();
      this.formDecision.controls.altOrder.updateValueAndValidity();
      this.formDecision.controls.altProd.clearValidators();
      this.formDecision.controls.altProd.updateValueAndValidity();
    } else {
      this.loadAltOrder();

      this.formDecision.controls.comment.clearValidators();
      this.formDecision.controls.comment.updateValueAndValidity();

      this.formDecision.controls.altOrder.setValidators([Validators.required]);
      this.formDecision.controls.altOrder.updateValueAndValidity();

      this.formDecision.controls.altProd.setValidators([Validators.required]);
      this.formDecision.controls.altProd.updateValueAndValidity();
    }
  }

  // выбор статуса
  setPayment(): void {
    switch (+this.formDecision.value.payment) {
      case 2:
        this.formDecision.controls.card.setValidators([Validators.required]);
        this.formDecision.controls.card.updateValueAndValidity();
        this.formDecision.controls.orderNumber.clearValidators();
        this.formDecision.controls.orderNumber.updateValueAndValidity();
        break;

      case 5:
        this.formDecision.controls.orderNumber.setValidators([
          Validators.required,
        ]);
        this.formDecision.controls.orderNumber.updateValueAndValidity();

        this.formDecision.controls.card.clearValidators();
        this.formDecision.controls.card.updateValueAndValidity();
        break;

      default:
        this.formDecision.controls.orderNumber.clearValidators();
        this.formDecision.controls.orderNumber.updateValueAndValidity();
        this.formDecision.controls.card.clearValidators();
        this.formDecision.controls.card.updateValueAndValidity();
        break;
    }
  }

  // расчет обязательных полей претензии
  changePath(): void {
    if (
      +this.formDecision.value.product === 4 ||
      +this.formDecision.value.product === 5
    ) {
      this.formDecision.controls.card.clearValidators();
      this.formDecision.controls.card.updateValueAndValidity();

      this.formDecision.controls.payment.clearValidators();
      this.formDecision.controls.payment.updateValueAndValidity();

      this.formDecision.controls.returnTtn.clearValidators();
      this.formDecision.controls.returnTtn.updateValueAndValidity();

      this.formDecision.controls.alt.clearValidators();
      this.formDecision.controls.alt.updateValueAndValidity();

      this.formDecision.get('sendSms').setValue(false);
    } else {
      this.formDecision.get('sendSms').setValue(true);

      this.formDecision.controls.card.setValidators([Validators.required]);
      this.formDecision.controls.card.updateValueAndValidity();

      this.formDecision.controls.payment.setValidators([Validators.required]);
      this.formDecision.controls.payment.updateValueAndValidity();

      this.formDecision.controls.returnTtn.setValidators([Validators.required]);
      this.formDecision.controls.returnTtn.updateValueAndValidity();

      this.formDecision.controls.alt.setValidators([Validators.required]);
      this.formDecision.controls.alt.updateValueAndValidity();

      this.setPayment();
      this.notReqCard();
      this.notReqReturnTtn();
    }
  }

  // Сохранить данные по решению
  onSaveDecision(): any {
    if (+this.preInfo.deliveryId === 6 || +this.preInfo.deliveryId === 7) {
      this.formDecision.controls.returnTtn.clearValidators();
      this.formDecision.controls.returnTtn.updateValueAndValidity();
    }

    if (this.formDecision.invalid) {
      this.srv.errorMessage('В решении заполенены не все поля');
      return false;
    }

    const obj = {
      id: this.pretensionId,
      card: this.formDecision.value.card,
      order: this.preInfo.orderNumber,
      payment: this.formDecision.value.payment,
      product: this.formDecision.value.product,
      sendSms: this.formDecision.value.sendSms,
      comment: this.formDecision.value.comment,
      orderNumber: this.formDecision.value.orderNumber,
      katalNumber: this.formDecision.value.katalNumber,
      brand: this.formDecision.value.brand,
      katalNumberCross: this.formDecision.value.katalNumberCross,
      brandCross: this.formDecision.value.brandCross,
      altOrder: this.formDecision.value.altOrder,
      altProd: this.formDecision.value.altProd,
      alt: this.formDecision.value.alt,
      cardReq: this.formDecision.value.cardReq,
      returnTtnReq: this.formDecision.value.returnTtnReq,
      returnTtn: this.formDecision.value.returnTtn,
      category: this.category.value,
      subCategory: this.subCategory.value,
      commentClose: this.comment.value,
    };

    this.task
      .onSaveDecision(obj)
      .pipe(takeUntil(this.destroy))
      .subscribe((val) => {
        if (+val.code === 200) {
          this.loadPretensionInfo();
          this.srv.successMessage('Данные по решению сохранены');
        } else {
          this.srv.errorMessage('Ошибка обратитесь к администратору');
        }
      });
  }

  // Завершить задачу
  onConfirmTask(): void {
    const dialog = this.dialog.open(DialogYesNoComponent, {
      data: {
        text:
          '<p>Вы собираетесь завершить притензию</b></p>' +
          '<h3 class="h3"><b>Вы уверены?</b></h3>',
        title: 'Завершить претензию',
        dismiss: 'Вернуться обратно',
        success: 'Закрыть претензию',
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.task
          .onConfirmPretension(this.pretensionId)
          .pipe(takeUntil(this.destroy))
          .subscribe(() => {
            this.loadPretensionInfo();
          });
      }
    });
  }

  // Отменить претензию
  dismissPretension(): void {
    const dialog = this.dialog.open(DialogYesNoComponent, {
      data: {
        text:
          '<p>Вы собираетесь отменить притензию</b></p>' +
          '<h3 class="h3"><b>Вы уверены?</b></h3>',
        title: 'Отменить претензию',
        dismiss: 'Вернуться обратно',
        success: 'Отменить претензию',
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (val.result) {
        this.task
          .onDismissPretension(this.pretensionId)
          .pipe(takeUntil(this.destroy))
          .subscribe(() => {
            this.route.navigate(['crm', 'order-card', this.orderNumber]);
          });
      }
    });
  }

  // Изменить сроки выполнения задачи
  onChangeDate(): void {
    const dialog = this.dialog.open(TaskDialogChangeTaskComponent, {
      data: {
        id: this.pretensionId,
        category: this.category.value,
        dateEnd: this.dateEnd.value,
      },
      maxHeight: '95vh',
      maxWidth: '95vw',
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.code === 200) {
        this.loadPretensionInfo();
        this.loadTaskComment();
      }
    });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  editManager(): void {
    const dialog = this.dialog.open(TaskDialogChangeManagerComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: {
        userId: this.preInfo.workman,
        taskId: this.pretensionId,
      },
    });

    dialog.afterClosed().subscribe((val) => {
      if (+val.code === 200) {
        this.loadPretensionInfo();
        this.srv.successMessage('Данные исполнитель изменен');
      }
    });
  }

  changeReason(): void {
    if (+this.category.value === 8) {
      this.formDecision.controls.katalNumber.setValidators([
        Validators.required,
      ]);
      this.formDecision.controls.katalNumber.updateValueAndValidity();

      this.formDecision.controls.brand.setValidators([Validators.required]);
      this.formDecision.controls.brand.updateValueAndValidity();

      this.formDecision.controls.katalNumberCross.setValidators([
        Validators.required,
      ]);
      this.formDecision.controls.katalNumberCross.updateValueAndValidity();

      this.formDecision.controls.brandCross.setValidators([
        Validators.required,
      ]);
      this.formDecision.controls.brandCross.updateValueAndValidity();
    } else {
      this.formDecision.controls.katalNumber.clearValidators();
      this.formDecision.controls.katalNumber.updateValueAndValidity();

      this.formDecision.controls.brand.clearValidators();
      this.formDecision.controls.brand.updateValueAndValidity();

      this.formDecision.controls.katalNumberCross.clearValidators();
      this.formDecision.controls.katalNumberCross.updateValueAndValidity();

      this.formDecision.controls.brandCross.clearValidators();
      this.formDecision.controls.brandCross.updateValueAndValidity();
    }
  }
}

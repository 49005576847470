<div class="container-fluid my-3">
  <div class="row align-items-center">
    <div class="col-12 col-md-4">
      <mat-form-field appearance="outline" style="width: 100%"
                      *ngIf="(structUnitList$|async) as structUnitList;else showLoad">
        <mat-label>Выберите структурную еденицу</mat-label>
        <mat-select [formControl]="structUnit" (selectionChange)="onSetSU()">
          <mat-option *ngFor="let item of structUnitList" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-template #showLoad>
        <div class="d-flex justify-content-center my-5">
          <mat-spinner diameter="25"></mat-spinner>
        </div>
      </ng-template>
    </div>
    <div class="col-12 col-md-4">
      <button mat-button class="btn btn-success" (click)="onCreateNewSU()">Создать СЕ</button>
    </div>
  </div>
  <br>
  <mat-divider></mat-divider>
  <div *ngIf="structUnit.value">
    <div class="row my-4">
      <div class="col-12 col-md-9">
        <mat-card class="card-box">
          <!--<mat-card-title>Справочник структурных единиц</mat-card-title>-->
          <mat-card-title>Редактирование</mat-card-title>
          <mat-card-content class="my-3">
            <div class="row">
              <div class="col-12 col-md-6">
                <mat-card class="card-box">
                  <form [formGroup]="form" (ngSubmit)="saveDataSU()">
                    <div class="row">
                      <div class="col">
                        <mat-form-field appearance="outline" style="width: 100%">
                          <mat-label>Название</mat-label>
                          <input matInput formControlName="name">
                          <mat-error *ngIf="form.get('name').hasError('required')">
                            Введите название организации
                          </mat-error>
                          <mat-error *ngIf="form.get('name').hasError('minlength')">
                            Название организации должно быть более 4 символов
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline" style="width: 100%;">
                          <mat-label>Код ЕГРПОУ</mat-label>
                          <input matInput formControlName="code">
                        </mat-form-field>
                      </div>
                      <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline" style="width: 100%">
                          <mat-label>Email</mat-label>
                          <input matInput
                                 type="email"
                                 formControlName="email"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline" style="width: 100%">
                          <mat-label>Телефон <span class="text-danger">*</span></mat-label>
                          <input matInput
                                 type="text"
                                 prefix="+38"
                                 [mask]="' (000) 000-00-99'"
                                 formControlName="phone"
                          />
                          <mat-error *ngIf="form.get('phone').hasError('required')">
                            Введите телефон
                          </mat-error>
                          <mat-error *ngIf="form.get('phone').hasError('minlength')">
                            Номер телефона некорректный
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <button type="submit" mat-button class="btn btn-success px-4">Сохранить</button>
                      </div>
                    </div>
                  </form>
                </mat-card>
                <mat-card class="card-box mt-3">
                  <div class="row my-2">
                    <div class="col">
                      <button mat-button class="btn btn-primary" (click)="addNewPayment()">Добавить банковские
                        реквизиты
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <mat-tab-group dynamicHeight>
                        <mat-tab label="Банковские карты" style="min-height: 200px">
                          <div class="row  my-2 py-2" style="border-bottom: 1px solid #808080"
                               *ngFor="let val of structUnit.value.payment.bc">
                            <div class="col-8">
                              <mat-slide-toggle [checked]="(val.action==1)"
                                                (toggleChange)="setDefaultPayment(val.value,1)">
                                {{val.text}}
                              </mat-slide-toggle>
                            </div>
                            <div class="col-4 text-right">
                              <button type="button" mat-icon-button class="btn btn-primary btn-sm"
                                      matTooltip="Удалить строку"
                                      matTooltipPosition="left"
                                      matTooltipClass="tooltip"
                                      (click)="deletePayment(val.value)"
                              >
                                <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
                              </button>
                            </div>
                          </div>

                        </mat-tab>
                        <mat-tab label="Расчетные счета">
                          <div class="row  my-2 py-2" style="border-bottom: 1px solid #808080"
                               *ngFor="let val of structUnit.value.payment.rs">
                            <div class="col-8">
                              <mat-slide-toggle [checked]="(val.action==1)"
                                                (toggleChange)="setDefaultPayment(val.value,2)">
                                {{val.text}}
                              </mat-slide-toggle>
                            </div>
                            <div class="col-4 text-right">
                              <button type="button" mat-icon-button class="btn btn-primary btn-sm"
                                      matTooltip="Удалить строку"
                                      matTooltipPosition="left"
                                      matTooltipClass="tooltip"
                                      (click)="deletePayment(val.value)"
                              >
                                <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
                              </button>
                            </div>
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                    </div>
                  </div>

                </mat-card>
              </div>
              <div class="col-12 col-md-6">
                <mat-card class="card-box">
                  <div class="row my-2">
                    <div class="col">
                      <button mat-button class="btn btn-primary" (click)="addNewDelivery()">Добавить API ключ
                        перевозчика
                      </button>
                    </div>
                  </div>
                  <mat-tab-group>
                    <mat-tab label="Новая почта">
                      <div class="row  my-2 py-2" style="border-bottom: 1px solid #808080"
                           *ngFor="let val of structUnit.value.delivery.np">
                        <div class="col-8">
                          <mat-slide-toggle [checked]="(val.action==1)"
                                            (toggleChange)="setDefaultDelivery(val.id,1)">
                            {{val.apiKey}} ({{val.dateEnd| date:'dd-MM-yyyy'}})
                          </mat-slide-toggle>
                        </div>
                        <div class="col-4 text-right">
                          <button type="button" mat-icon-button class="btn btn-primary btn-sm"
                                  matTooltip="Удалить строку"
                                  matTooltipPosition="left"
                                  matTooltipClass="tooltip"
                                  (click)="deleteDelivery(val.id)"
                          >
                            <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
                          </button>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </mat-card>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <mat-card class="card-box my-2">
          <mat-card-content>
            <form [formGroup]='showInfo'>

              <div class="row align-items-center">
                <div class="col-auto">
                  <mat-form-field>
                    <mat-label>Диапазон</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                      <input
                        matStartDate
                        formControlName="start"
                        placeholder="Дата начала">
                      <input matEndDate formControlName="end" placeholder="Дата конца">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker (closed)="onLoadPayData()"></mat-date-range-picker>
                  </mat-form-field>
                </div>
                <div class="col-auto">
                    <mat-checkbox color="primary" (change)="onLoadPayData()" formControlName="returnProd">С учетом возвратов</mat-checkbox>
                </div>
              </div>
            </form>

            <div class="row">
              <div class="col-12 col-md-6">
                <div class="row" *ngIf="(onLoadPayData$| async) as onLoadPayData">
                  <div class="col-12">
                    <p-chart type="pie" [data]="onLoadPayData.pieData"></p-chart>
                  </div>
                  <div class="col-12">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th>Способ отгрузки</th>
                        <th>Сумма</th>
                        <th>%</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let val of onLoadPayData.costData" (click)="showOrderInfo(val.pay,1)">
                        <td>{{val.name}}</td>
                        <td>{{val.value}}</td>
                        <td>{{srv.roundNumber(val.value * 100 / onLoadPayData.sumCost)}}</td>
                      </tr>
                      <tr class="table-warning-row">
                        <td></td>
                        <td>{{onLoadPayData.sumCost}}</td>
                        <td>100</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row" *ngIf="(onLoadPayDataDelivery$| async) as onLoadPayDataDelivery">
                  <div class="col-12">
                    <p-chart type="pie" [data]="onLoadPayDataDelivery.pieData"></p-chart>
                  </div>
                  <div class="col-12">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th>Способ отгрузки</th>
                        <th>Сумма</th>
                        <th>%</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let val of onLoadPayDataDelivery.costData" (click)="showOrderInfo(val.delivery,2)">
                        <td>{{val.name}}</td>
                        <td>{{val.value}}</td>
                        <td>{{srv.roundNumber(val.value * 100 / onLoadPayDataDelivery.sumCost)}}</td>
                      </tr>
                      <tr class="table-warning-row">
                        <td></td>
                        <td>{{onLoadPayDataDelivery.sumCost}}</td>
                        <td>100</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </mat-card-content>
        </mat-card>

      </div>
      <div class="col-12 col-md-3">
        <mat-card class="card-box">
          <mat-card-subtitle>Лог</mat-card-subtitle>
          <mat-card-content style="max-height: 400px;overflow-y: auto">
            <div class="p-2 log-div" *ngFor="let item of structUnit.value.logList">
              <div>{{item.comment}}</div>
              <div mat-line><small>{{item.dateCreate|date:'dd-MM-yyyy HH:mm:ss'}} - {{item.manager}}</small></div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid my-3">
  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title>Сводка ключевых показателей</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content class="pt-3">
          <form [formGroup]="form" id="filterForm" (ngSubmit)="getGrossInfo()">
            <div class="row">
              <div class="col-auto">
                <mat-form-field>
                  <mat-label>Диапазон</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input
                      matStartDate
                      formControlName="start"
                      placeholder="Дата начала"
                    />
                    <input
                      matEndDate
                      formControlName="end"
                      placeholder="Дата конца"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions align="end">
          <button
            mat-button
            type="submit"
            class="btn btn-success"
            form="filterForm"
          >
            Отправить
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div class="row my-3">
    <div class="col">
      <p-table
        [columns]="cols"
        [value]="salaryList"
        [loading]="loading"
        [rowsPerPageOptions]="[10, 25, 50, 100]"
        [paginator]="true"
        currentPageReportTemplate="Элементов на странице ({first} - {last}) з {totalRecords} "
        [filterDelay]="0"
        autoLayout="true"
        [rows]="10"
        selectionMode="single"
        styleClass="p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              style="font-weight: 400; font-size: 12px"
            >
              <p-sortIcon [field]="col.field"></p-sortIcon>
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [class]="rowData['class']">
            <td
              *ngFor="let col of columns; let index = index"
              (click)="loadMoreInfo(index, rowData)"
              style="font-weight: 300; font-size: 12px"
            >
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr [class]="footer.class">
            <td>Итого</td>
            <td
              (click)="
                loadMoreInfo(
                  1,
                  {
                    check: 0,
                    conv: '',
                    manager: '',
                    managerId: 0,
                    presentations: 0,
                    sales: 0,
                    successfulOrders: 0,
                    uniqAllProd: 0,
                    uniqReturnProd: 0
                  },
                  managerArr
                )
              "
            >
              {{ footer.presentations }}
            </td>
            <td
              (click)="
                loadMoreInfo(
                  2,
                  {
                    check: 0,
                    conv: '',
                    manager: '',
                    managerId: 0,
                    presentations: 0,
                    sales: 0,
                    successfulOrders: 0,
                    uniqAllProd: 0,
                    uniqReturnProd: 0
                  },
                  managerArr
                )
              "
            >
              {{ footer.successfulOrders }}
            </td>
            <td>
              {{
                srv.roundNumber(
                  (footer.successfulOrders * 100) / footer.presentations
                )
              }}
            </td>
            <td
              (click)="
                loadMoreInfo(
                  4,
                  {
                    check: 0,
                    conv: '',
                    manager: '',
                    managerId: 0,
                    presentations: 0,
                    sales: 0,
                    successfulOrders: 0,
                    uniqAllProd: 0,
                    uniqReturnProd: 0
                  },
                  managerArr
                )
              "
            >
              {{ footer.sales }}
            </td>
            <td>
              {{ srv.roundNumber(footer.sales / footer.successfulOrders) }}
            </td>

            <td
              (click)="
                loadMoreInfo(
                  6,
                  {
                    check: 0,
                    conv: '',
                    manager: '',
                    managerId: 0,
                    presentations: 0,
                    sales: 0,
                    successfulOrders: 0,
                    uniqAllProd: 0,
                    uniqReturnProd: 0
                  },
                  managerArr
                )
              "
            >
              {{ footer.uniqAllProd }}
            </td>
            <td>{{ footer.uniqAllDopProd }}</td>
            <td
              (click)="
                loadMoreInfo(
                  7,
                  {
                    check: 0,
                    conv: '',
                    manager: '',
                    managerId: 0,
                    presentations: 0,
                    sales: 0,
                    successfulOrders: 0,
                    uniqAllProd: 0,
                    uniqReturnProd: 0
                  },
                  managerArr
                )
              "
            >
              {{ footer.uniqReturnProd }}
            </td>
            <td
              (click)="
                loadMoreInfo(
                  8,
                  {
                    check: 0,
                    conv: '',
                    manager: '',
                    managerId: 0,
                    presentations: 0,
                    sales: 0,
                    successfulOrders: 0,
                    uniqAllProd: 0,
                    uniqReturnProd: 0
                  },
                  managerArr
                )
              "
            >
              {{
                srv.roundNumber(
                  (footer.uniqReturnProd * 100) / footer.uniqAllProd
                )
              }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { RequestBody } from '@core/interfaces/interfaces';
import { ApiRequest, Request_PAYMENT } from '../enum';
import { Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { IFastFilter, IMenuCrm } from '../interface';
import { DicList, SprList } from '../interface';
import { TableData } from '../interface/table-data';
import { IDopSaleList, IDopSaleProduct, IResultData } from 'repositories';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(private api: ApiService) {}

  postRequest(className: string, method: string, value?: any): Observable<any> {
    return this.api.post_jwt({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }

  loadMenu(value?): Observable<IMenuCrm[]> {
    return this.api.post_jwt(
      this.getRequest(value, ApiRequest.CLASS, ApiRequest.METHOD_LOAD_MENU)
    );
  }

  loadDicInfo(value?): Observable<DicList> {
    return this.api.post_jwt(
      this.getRequest(value, ApiRequest.CLASS, ApiRequest.METHOD_LOAD_DIC_INFO)
    );
  }

  loadOrderList(value?): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_ORDER_LIST
      )
    );
  }

  loadFastProduct(value?): Observable<IDopSaleList[]> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_FAST_PRODUCT
      )
    );
  }

  addProductToOrder(value?): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_ADD_PRODUCT_TO_ORDER
      )
    );
  }

  loadMarkProtection(value): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_MARK_PROTECTION
      )
    );
  }

  loadHelpInfo(value?): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(value, ApiRequest.CLASS, ApiRequest.METHOD_LOAD_HELP_INFO)
    );
  }

  searchClientInfo(value): Observable<SprList[]> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_SEARCH_CLIENT_INFO
      )
    );
  }

  createNewClient(value: any): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_CREATE_NEW_CLIENT
      )
    );
  }

  loadTaskForMe(value?): Observable<TableData> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_TASK_FOR_ME
      )
    );
  }

  loadTaskFromMe(value?): Observable<TableData> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_TASK_FROM_ME
      )
    );
  }

  loadPretension(value?): Observable<TableData> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_PRETENSION
      )
    );
  }

  loadRequest(value?): Observable<TableData> {
    return this.api.post_jwt(
      this.getRequest(value, ApiRequest.CLASS, ApiRequest.METHOD_LOAD_REQUEST)
    );
  }

  setTaskToWork(value?): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_SET_TASK_TO_WORK
      )
    );
  }

  setTaskToArchive(value?: any): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_SET_TASK_TO_ARCHIVE
      )
    );
  }

  loadRandomProd(value?): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_RANDOM_PROD
      )
    );
  }

  private getRequest(value, className: string, method: string): RequestBody {
    return {
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    };
  }

  setNotFound(value: any): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        Request_PAYMENT.CLASS,
        Request_PAYMENT.METHOD_SET_NOT_FOUND_RANDOM_PROD
      )
    );
  }

  getNewSiteCost(value: { priceCost: any }): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        Request_PAYMENT.CLASS,
        Request_PAYMENT.METHOD_GET_NEW_SITE_COST
      )
    );
  }

  setApproveProduct(value: any): Observable<any> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        Request_PAYMENT.CLASS,
        Request_PAYMENT.METHOD_SET_APPROVE_PRODUCT
      )
    );
  }

  loadProviderList(value?): Observable<SprList[]> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_PROVIDER_LIST
      )
    );
  }

  loadFastProdList(value): Observable<IFastFilter<IDopSaleProduct>> {
    return this.api.post_jwt(
      this.getRequest(
        value,
        ApiRequest.CLASS,
        ApiRequest.METHOD_LOAD_FAST_PRODUCT_LIST
      )
    );
  }
}

import { Observable, of } from 'rxjs';
import { OtherSpr, RequestBody } from '../../../../core/interfaces/interfaces';
import { ApiService } from '../../../../core/services/api.service';
import {
  LastContact,
  SmallCallList,
  UclSmallCard,
  UserCard,
  UserCardPhone,
  UserDataCarTable,
  UserDataOrder,
  UserDataOrderTable,
  UserPayment,
  UserRecipient,
} from './ucl';
import { Injectable } from '@angular/core';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable()
export class UclService {
  constructor(private api: ApiService) {}

  loadUserCardList(obj): Observable<UclSmallCard[]> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'getUserCardList',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadActionUserList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 2,
        listId: 8,
      },
    };
    return this.api.post_jwt(q);
  }

  loadTypeUserList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 2,
        listId: 9,
      },
    };
    return this.api.post_jwt(q);
  }

  loadingUserCardInfo(hash): Observable<UserCard> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadingUserCardInfo',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  loadUserRecipient(hash): Observable<UserRecipient[]> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadUserRecipient',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  loadUserPayment(hash): Observable<UserPayment[]> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadUserPayment',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCallList(hash): Observable<SmallCallList[]> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadCallList',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  loadLastContactList(hash): Observable<LastContact[]> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadLastContactList',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  loadUserOrderInfo(hash, specType?): Observable<UserDataOrder> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadUserOrderInfo',
      methodProperties: {
        type: 2,
        hash,
        specType,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCarList(hash): Observable<UserDataCarTable> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadCarList',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  loadUserOrderList(hash): Observable<UserDataOrderTable> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadUserOrderList',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  onCreateNewOrderByCar(hash, id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onCreateNewOrderBaseOnCar',
      methodProperties: {
        type: 2,
        hash,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  onDeleteCar(hash, id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'onDeleteClientCars',
      methodProperties: {
        type: 2,
        hash,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  onCreateNewOrder(hash, saleForm): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onCreateNewOrder',
      methodProperties: {
        type: 2,
        hash,
        saleForm,
      },
    };
    return this.api.post_jwt(q);
  }

  onDeleteBankCard(hash, id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'onDeleteBankCard',
      methodProperties: {
        type: 2,
        hash,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  onDeleteRecipient(hash, id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'onDeleteRecipient',
      methodProperties: {
        type: 2,
        hash,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  addNewPayment(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'onSaveBankCard',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  editPayment(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'onEditBankCard',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  addNewRecipient(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'onCreateNewRecipient',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  editRecipient(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'onEditRecipient',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadMarkList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getAllMarkaCrm',
      methodProperties: {
        type: 2,
      },
    };
    return this.api.post_jwt(q).pipe(
      filter((f) => +f.code === 200),
      map((data) => data.mark),
      catchError((err) => of(' Error in methods getAllMarka ' + err))
    );
  }

  loadModelList(mark): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getModelPerMarkName',
      methodProperties: {
        type: 2,
        mark,
      },
    };
    return this.api.post_jwt(q).pipe(
      filter((f) => +f.code === 200),
      map((data) => data.model),
      catchError((err) => of(' Error in methods getModelPerMarkName ' + err))
    );
  }

  addNewCar(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'addNewCar',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  editCar(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'editCar',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  editUser(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'editUser',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCarInfo(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadCarInfo',
      methodProperties: {
        type: 2,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  loadTypeUser(): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 2,
        listId: 9,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSpecInfoUser(): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 2,
        listId: 10,
      },
    };
    return this.api.post_jwt(q);
  }

  updateCallInfo(callId, orderNumber, obj?): Observable<any> {
    const q: RequestBody = {
      modelName: 'VoIp',
      calledMethod: 'updateCallInfo',
      methodProperties: {
        type: 2,
        callId,
        orderNumber,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  createNewOrderPhone(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onCreateNewOrderPhoneType2',
      methodProperties: {
        type: 2,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadUserCardPhone(hash, idRow): Observable<UserCardPhone> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'loadUserCardPhone',
      methodProperties: {
        type: 2,
        hash,
        idRow,
      },
    };
    return this.api.post_jwt(q);
  }

  addCarWithMark(hash): Observable<UserCardPhone> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'addCarWithMark',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  createPersonalCabinet(hash: string): Observable<any> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'createPersonalCabinet',
      methodProperties: {
        type: 2,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  actionList(): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 2,
        listId: 77,
      },
    };
    return this.api.post_jwt(q);
  }

  managerList(): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprManager',
      methodProperties: {
        type: 1,
        managerList: 4,
      },
    };
    return this.api.post_jwt(q);
  }

  cashBackList(): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'cashBackList',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }
}

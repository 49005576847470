<lib-title-modal-window [title]="title"></lib-title-modal-window>
<mat-card-content>
  <mat-tab-group>
    <mat-tab label="Задачи на меня">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-slide-toggle
              #slideTask
              [formControl]="taskForMeSlide"
              color="primary"
              (change)="loadTaskForMe()"
            >
              {{ slideTask.checked ? "Архив" : "Актуальные задачи" }}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row" *ngIf="loadTaskForMe$ | async as loadTaskForMe">
          <div class="col">
            <lib-count-table-info
              [resultData]="loadTaskForMe"
              (setTaskWork)="setTaskToWork($event)"
            ></lib-count-table-info>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Задачи от меня">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-slide-toggle
              #slideTaskFrom
              [formControl]="taskFromMeSlide"
              color="primary"
              (change)="loadTaskFromMe()"
            >
              {{ slideTaskFrom.checked ? "Архив" : "Актуальные задачи" }}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row" *ngIf="loadTaskFromMe$ | async as loadTaskFromMe">
          <div class="col">
            <lib-count-table-info
              [resultData]="loadTaskFromMe"
              (setToArchive)="setToArchive($event)"
            ></lib-count-table-info>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Претензии">
      <div class="container-fluid">
        <div class="row" *ngIf="loadPretension$ | async as loadPretension">
          <div class="col">
            <lib-count-table-info
              [resultData]="loadPretension"
            ></lib-count-table-info>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Запросы">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-slide-toggle
              #slideRequest
              [formControl]="requestSlide"
              color="primary"
              (change)="loadRequestSlide()"
            >
              {{ slideRequest.checked ? "Архив" : "Актуальные запросы" }}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row" *ngIf="loadRequest$ | async as loadRequest">
          <div class="col">
            <lib-count-table-info
              [resultData]="loadRequest"
            ></lib-count-table-info>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      label="Заказ товара"
      *ngIf="showRandomProdList && showRandomProdList.length > 0"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <mat-slide-toggle
              #slideRandomProduct
              [formControl]="randomProd"
              color="primary"
              (change)="loadTaskRandomInfo()"
            >
              {{ slideRandomProduct.checked ? "Архив" : "Актуальные заказы" }}
            </mat-slide-toggle>
          </div>
        </div>
        <div class="row" *ngIf="loadRandomProd$ | async as loadRandomProd">
          <div class="col">
            <lib-count-table-info
              (showCardRandom)="showRandomCard($event)"
              [resultData]="loadRandomProd"
            ></lib-count-table-info>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card-content>

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../../../core/services/api.service';
import { RequestBody } from '../../../../core/interfaces/interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dialog-gross-info',
  templateUrl: './dialog-gross-info.component.html',
  styleUrls: ['./dialog-gross-info.component.scss'],
})
export class DialogGrossInfoComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  cols = [];
  rows = [];
  footer = {
    class: '',
    colspan: 0,
    zakaz: 0,
    createDate: '',
    sumOrder: 0,
    manager: '',
  };
  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.onLoadMoreInfo();
  }

  onLoadMoreInfo(): void {
    this.loading = true;
    const dialogInfo = this.dialogData;

    const field = dialogInfo.field;
    const typeFilter = dialogInfo.typeFilter;
    const source = dialogInfo.source;
    const formSale = dialogInfo.formSale;
    const manager = dialogInfo.manager;
    const car = dialogInfo.car;
    const prodGroup = dialogInfo.prodGroup;
    const payment = dialogInfo.payment;
    const delivery = dialogInfo.delivery;
    const brand = dialogInfo.brand;
    const startDate = dialogInfo.startDate;
    const endDate = dialogInfo.endDate;
    const filterId = dialogInfo.filterId;
    const orderDataType = dialogInfo.orderDataType;
    const typeProduct = dialogInfo.typeProduct;

    const q: RequestBody = {
      modelName: 'AnalyticsGross',
      calledMethod: 'getGrossMoreAnalytics',
      methodProperties: {
        type: 1,
        startDate,
        endDate,
        field,
        filterId,
        typeFilter,
        source,
        formSale,
        manager,
        car,
        prodGroup,
        payment,
        delivery,
        brand,
        orderDataType,
        typeProduct,
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.loading = false;

      this.cols = spr.headerList;
      this.rows = spr.orderList;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

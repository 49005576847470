<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2">
      <b>{{ "SALE.TASK.CONFIRM_TASK.TITLE" | translate }}</b>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<form [formGroup]="form" (ngSubmit)="confirmTask()">
  <mat-card-content>
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{ "SALE.TASK.CONFIRM_TASK.RESULT" | translate }}</mat-label>
      <textarea matInput formControlName="comment" [rows]="5"></textarea>
      <mat-error *ngIf="form.get('comment').hasError('required')">
        {{ "SALE.TASK.CONFIRM_TASK.RESULT_ERR" | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('comment').hasError('minlength')">
        Комментарий минимум 15 символов ({{ form.get("comment").value.length }})
      </mat-error>
    </mat-form-field>
  </mat-card-content>

  <mat-card-actions align="end">
    <button type="submit" mat-button class="btn btn-success">
      {{ "SALE.TASK.CONFIRM_TASK.BTN" | translate }}
    </button>
  </mat-card-actions>
</form>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@shared/not-found/not-found.component';
import { LoginComponent } from '@shared/login/login.component';
import { GuardService } from '@core/services/guard.service';
import { CountProfitComponent } from '@crm/analytics/count-profit/count-profit.component';
import { ReturnPageComponent } from '@crm/analytics/return-page/return-page.component';
import { GrossPageComponent } from '@crm/analytics/gross-page/gross-page.component';
import { SalaryPageComponent } from '@crm/analytics/salary-page/salary-page.component';
import { ClientProfitComponent } from '@crm/analytics/client-profit/client-profit.component';
import { CallListComponent } from '@crm/sale/call-list/call-list.component';
import { ProdProfitComponent } from '@crm/analytics/prod-profit/prod-profit.component';
import { StructUnitDicComponent } from '@crm/settings/struct-unit-dic/struct-unit-dic.component';
import { PackingComponent } from '@crm/sale/delivery/packing/packing.component';
import { UserCardLibraryComponent } from '@crm/settings/user-card-library/user-card-library.component';
import { UserCardComponent } from '@crm/settings/user-card-library/user-card/user-card.component';
import { RomiComponent } from '@crm/analytics/romi/romi.component';
import { TaskComponent } from '@crm/sale/task/task/task.component';
import { PretensionComponent } from '@crm/sale/task/pretension/pretension.component';
import { SmallSearchComponent } from '@crm/search/small-search/small-search.component';
import { GlobalSearchComponent } from '@crm/search/global-search/global-search.component';
import { ProductCardComponent } from '@crm/search/product-card/product-card.component';
import { SelectionCatalogComponent } from '@crm/sale/selection-catalog/selection-catalog.component';
import { RedirectCatalogComponent } from '@shared/redirect-catalog/redirect-catalog.component';
import { PermissionGuardService } from '@core/services/permission-guard.service';
import { MainLayoutComponent } from '@shared/main-layout/main-layout.component';

const routes: Routes = [
  { path: '', redirectTo: '/crm', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  // Модуль печатных форм
  {
    path: 'print',
    loadChildren: () =>
      import('./shared/print/print.module').then((m) => m.PrintModule),
    canActivate: [GuardService],
  },
  {
    path: 'redirect-catalog/:katal/:brand',
    component: RedirectCatalogComponent,
  },

  {
    path: 'crm',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('main-page').then((m) => m.HomePageModule),
        canActivate: [GuardService],
      },
      {
        path: 'count-profit',
        component: CountProfitComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'client-profit',
        component: ClientProfitComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'romi',
        component: RomiComponent,
        canActivate: [GuardService, PermissionGuardService],
      },

      // Аналитика упаковки
      {
        path: 'packing-profit',
        loadChildren: () =>
          import('./crm/analytics/packing-profit/packing-profit.module').then(
            (m) => m.PackingProfitModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Количественная аналитика
      {
        path: 'count-new-profit',
        loadChildren: () =>
          import(
            './crm/analytics/new-count-profit/new-count-profit.module'
          ).then((m) => m.NewCountProfitModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Количественная аналитика
      {
        path: 'prod-analytics',
        loadChildren: () =>
          import(
            './crm/settings/product/prod-analytics/prod-analytics.module'
          ).then((m) => m.ProdAnalyticsModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // romi google
      {
        path: 'romi-google',
        loadChildren: () =>
          import('./crm/analytics/romi-goole/romi-goole.module').then(
            (m) => m.RomiGooleModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // romi google
      {
        path: 'fids-tools',
        loadChildren: () => import('fids-tools').then((m) => m.FidsToolsModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Карточка товара
      {
        path: 'order-card/:orderNumber',
        loadChildren: () => import('order-card').then((m) => m.OrderCardModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'order-management-2',
        loadChildren: () =>
          import('order-management').then((m) => m.OrderManagementModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // баланс поставщика
      {
        path: 'balance-provider-2',
        loadChildren: () =>
          import('balance-provider').then((m) => m.BalanceProviderModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Карточка товара
      {
        path: 'user-card-phone/:hash/:callId',
        loadChildren: () => import('call-card').then((m) => m.CallCardModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Аналитика количественная 2.0
      {
        path: 'count-analytics',
        loadChildren: () =>
          import('count-analytics').then((m) => m.CountAnalyticsModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Аналитика финансовая 2.0
      {
        path: 'gross-analytics',
        loadChildren: () =>
          import('gross-analytics').then((m) => m.GrossAnalyticsModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Катотека товаров 2.0
      {
        path: 'product-card-library',
        loadChildren: () =>
          import('product-card-library').then(
            (m) => m.ProductCardLibraryModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },
      // управение структурными еденицами
      {
        path: 'unit-management',
        loadChildren: () =>
          import('unit-management').then((m) => m.UnitManagementModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'task-manager',
        loadChildren: () =>
          import('task-manager').then((m) => m.TaskManagerModule),
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'nomen-manager',
        loadChildren: () =>
          import('nomen-manager').then((m) => m.NomenManagerModule),
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'prediction-management',
        loadChildren: () =>
          import('prediction-plan-management').then(
            (m) => m.PredictionManagementModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'payment-product',
        loadChildren: () =>
          import('payment-product').then((m) => m.PaymentProductModule),
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'settings',
        loadChildren: () => import('settings').then((m) => m.SettingsModule),
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'return-profit',
        component: ReturnPageComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'gross-profit',
        component: GrossPageComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'salary-profit',
        component: SalaryPageComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'call-list',
        component: CallListComponent,
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'prod-profit',
        component: ProdProfitComponent,
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'settings-struct-unit-dic',
        component: StructUnitDicComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'packing',
        component: PackingComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'task/:id',
        component: TaskComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'pretension/:id',
        component: PretensionComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      /*      {
        path: 'order-card/:orderNumber',
        component: OrderCardComponent,
        canActivate: [GuardService, PermissionGuardService],
      },*/
      {
        path: 'user-card-library',
        component: UserCardLibraryComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'user-card/:hash',
        component: UserCardComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'search/:qk',
        loadChildren: () =>
          import('search-small-page').then((m) => m.SearchSmallPageModule),
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'search-global/:qk/:qb',
        component: GlobalSearchComponent,
        canActivate: [GuardService, PermissionGuardService],
      },
      {
        path: 'product/:code',
        component: ProductCardComponent,
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'selection-catalog/:mark',
        component: SelectionCatalogComponent,
        canActivate: [GuardService],
      },

      // окно подтверждения возвратов
      {
        path: 'photo-edit',
        loadChildren: () =>
          import('./crm/settings/product/photo-edit/photo-edit.module').then(
            (m) => m.PhotoEditModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // аналитика звонков

      // Возвраты
      {
        path: 'return-product',
        loadChildren: () =>
          import('return-product').then((m) => m.ReturnProductModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'prod-exist-photo',
        loadChildren: () =>
          import(
            './crm/settings/product/prod-exist-photo/prod-exist-photo.module'
          ).then((m) => m.ProdExistPhotoModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'voip-call',
        loadChildren: () =>
          import('./crm/analytics/voip-call/voip-call.module').then(
            (m) => m.VoipCallModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Управление заказами
      {
        path: 'order-management',
        loadChildren: () =>
          import('./crm/sale/order-management/order-management.module').then(
            (m) => m.OrderManagementModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Отправка
      {
        path: 'send-order',
        loadChildren: () =>
          import('./crm/sale/delivery/send/send.module').then(
            (m) => m.SendModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },
      // Склад
      {
        path: 'warehouse',
        loadChildren: () =>
          import('./crm/warehouse/warehouse-registry/warehouse.module').then(
            (m) => m.WarehouseModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Работа с заказми
      {
        path: 'work-list',
        loadChildren: () =>
          import('./crm/sale/work-list/work-list.module').then(
            (m) => m.WorkListModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Список заказов
      {
        path: 'order-list',
        loadChildren: () =>
          import('./crm/sale/order-list/order-list.module').then(
            (m) => m.OrderListModule
          ),
        canActivate: [GuardService],
      },
      // Панель оперативного контроля качества сервиса
      {
        path: 'quality-panel',
        loadChildren: () =>
          import('./crm/sale/quality-panel/quality-panel.module').then(
            (m) => m.QualityPanelModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },
      // Баланс клиента
      {
        path: 'balance-client/:hash',
        loadChildren: () =>
          import('./crm/bookkeeping/balance-client/balance-client.module').then(
            (m) => m.BalanceClientModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Сводный баланс предприятия
      {
        path: 'provider-balance',
        loadChildren: () =>
          import(
            './crm/bookkeeping/balance-provider/balance-provider.module'
          ).then((m) => m.BalanceProviderModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Сводный баланс предприятия
      {
        path: 'balance-provider',
        loadChildren: () =>
          import(
            './crm/bookkeeping/provider-balance/provider-balance.module'
          ).then((m) => m.ProviderBalanceModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Сводный баланс предприятия
      {
        path: 'consolidated-balance',
        loadChildren: () =>
          import(
            './crm/bookkeeping/consolidated-balance/consolidated-balance.module'
          ).then((m) => m.ConsolidatedBalanceModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // касса
      {
        path: 'cashbox',
        loadChildren: () =>
          import('./crm/bookkeeping/cashbox/cash-box.module').then(
            (m) => m.CashBoxModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Фиксация оплат за заказы
      {
        path: 'add-order-payment',
        loadChildren: () =>
          import(
            './crm/bookkeeping/add-order-payment/add-order-payment.module'
          ).then((m) => m.AddOrderPaymentModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Картотека товаров
      {
        path: 'product-library',
        loadChildren: () =>
          import(
            './crm/settings/product/product-library/product-library.module'
          ).then((m) => m.ProductLibraryModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Картотека товаров
      {
        path: 'product-edit-rec-list',
        loadChildren: () =>
          import(
            './crm/settings/product/product-edit-rec-list/product-edit-rec-list.module'
          ).then((m) => m.ProductEditRecListModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Картотека товаров
      {
        path: 'request-ads-filter',
        loadChildren: () =>
          import(
            './crm/settings/request-ads-filter/request-ads-filter.module'
          ).then((m) => m.RequestAdsFilterModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Управление брендами
      {
        path: 'product-brand-management',
        loadChildren: () =>
          import(
            './crm/settings/product/product-brand-management/product-brand-management.module'
          ).then((m) => m.ProductBrandManagementModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Управление брендами
      {
        path: 'product-edit-card/:katalNumber/:brand',
        loadChildren: () =>
          import(
            './crm/settings/product/product-card/product-card.module'
          ).then((m) => m.ProductCardModule),
        canActivate: [GuardService, PermissionGuardService],
      },
      // Управление товарными группами

      {
        path: 'product-tree-management',
        loadChildren: () =>
          import(
            './crm/settings/product/product-tree-management/product-tree-management.module'
          ).then((m) => m.ProductTreeManagementModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Закрытие заказов
      {
        path: 'close-order',
        loadChildren: () =>
          import('./crm/bookkeeping/close-order/close-order.module').then(
            (m) => m.CloseOrderModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },
      // Справочник поставщиков
      {
        path: 'settings-provider',
        loadChildren: () =>
          import('provider-management').then((m) => m.ProviderManagementModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Справочник поставщиков
      {
        path: 'dop-sale-analytics',
        loadChildren: () =>
          import('dop-sale-analytics').then((m) => m.DopSaleAnalyticsModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'resume-product',
        loadChildren: () =>
          import('../../projects/resume-product/index').then(
            (m) => m.ResumeProductModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },

      // Упарелние временем персонала
      {
        path: 'work-time-planned',
        loadChildren: () =>
          import(
            './crm/settings/work-time-planned/work-time-planned.module'
          ).then((m) => m.WorkTimePlannedModule),
        canActivate: [GuardService, PermissionGuardService],
      },

      {
        path: 'cost-accounting',
        loadChildren: () =>
          import('./crm/cost-accounting/cost-accounting.module').then(
            (m) => m.CostAccountingModule
          ),
        canActivate: [GuardService],
      },
      // управление банковскими выписками
      {
        path: 'bank-statement',
        loadChildren: () =>
          import('./crm/bank-statement/bank-statement.module').then(
            (m) => m.BankStatementModule
          ),
        canActivate: [GuardService, PermissionGuardService],
      },
    ],
    canActivate: [GuardService],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

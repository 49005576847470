import { NgModule } from '@angular/core';
import { ListTableComponent } from './list-table.component';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { SharedProviderCountTableModule } from 'shared-provider-count-table';

@NgModule({
  declarations: [ListTableComponent],
  imports: [
    TableModule,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    InputTextModule,
    MatButtonModule,
    MatMenuModule,
    SharedProviderCountTableModule,
  ],
  exports: [ListTableComponent],
})
export class ListTableModule {}

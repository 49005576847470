import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaitWindowLoadingComponent } from './wait-window-loading.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [WaitWindowLoadingComponent],
  imports: [CommonModule, MatCardModule, MatProgressSpinnerModule],
  exports: [WaitWindowLoadingComponent],
})
export class WaitWindowLoadingModule {}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatService } from '../core/chat.service';
import { takeUntil, tap } from 'rxjs/operators';
import { OtherSpr } from '@core/interfaces/interfaces';
import { SrvService } from 'repositories';

@Component({
  selector: 'lib-warehouse-chat',
  templateUrl: './warehouse-chat.component.html',
  providers: [ChatService],
})
export class WarehouseChatComponent implements OnInit, OnDestroy {
  destroy = new ReplaySubject();

  form: FormGroup;
  countList = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
  ];
  warehouseList$: Observable<OtherSpr[]>;
  prodInfo$: Observable<any>;
  prodInfoReq: any;
  styleResponse: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private chat: ChatService,
    public srv: SrvService,
    private dialogRef: MatDialogRef<WarehouseChatComponent>
  ) {
    this.form = new FormGroup({
      reqCat: new FormControl(3, [Validators.required]),
      warehouse: new FormControl(),
      count: new FormControl(''),
      data: new FormControl(''),
      comment: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.loadWarehouse();
    this.loadProdInfo();
  }

  loadWarehouse(): void {
    this.warehouseList$ = this.chat.loadWarehouse(
      this.dialogData.id,
      this.dialogData.num
    );
  }

  loadProdInfo(): void {
    this.prodInfo$ = this.chat.loadProdInfo(this.dialogData.id).pipe(
      tap((val) => {
        this.prodInfoReq = val;

        this.form.get('warehouse').setValue(val.codeProvider);
        this.form.get('count').setValue(+val.count);
        this.form
          .get('data')
          .setValue(
            this.srv.convertDateTimeInput(
              val.planDateSend + ' ' + val.planDateSendTime
            )
          );
        this.changeResponse();
      })
    );
  }

  onCreateNewRequest(): any {
    if (this.form.invalid) {
      this.srv.errorMessage('Не все поля заполнены');

      return false;
    }
    const obj = {
      typeRequest: 1,
      category: this.form.value.reqCat,
      title: this.prodInfoReq.katalNumber + ' ' + this.prodInfoReq.brand,
      productId: this.dialogData.id,
      text_1: this.prodInfoReq.codeProvider + '|' + this.form.value.warehouse,
      text_2: this.prodInfoReq.count + '|' + this.form.value.count,
      text_3:
        this.srv.convertDateTime(
          this.prodInfoReq.planDateSend +
            ' ' +
            this.prodInfoReq.planDateSendTime
        ) +
        '|' +
        this.srv.convertDateTime(this.form.value.data),
      comment: this.form.value.comment,
    };

    this.chat
      .onCreateNewRequestOrder(obj)
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        if (+value.code === 200) {
          this.dialogRef.close({ code: 200 });
          this.srv.successMessage('Запрос создан');
        }
      });
  }

  changeResponse(): void {
    if (+this.prodInfoReq.codeProvider !== +this.form.value.warehouse) {
      this.styleResponse = false;
    } else if (+this.prodInfoReq.count !== +this.form.value.count) {
      this.styleResponse = false;
    } else if (
      this.srv.convertDateTime(
        this.prodInfoReq.planDateSend + ' ' + this.prodInfoReq.planDateSendTime
      ) !== this.srv.convertDateTime(this.form.value.data)
    ) {
      this.styleResponse = false;
    } else if (this.form.value.comment) {
      this.styleResponse = false;
    } else {
      this.styleResponse = true;
    }
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}

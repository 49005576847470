<table class="table table-bordered table-sm mb-0">
  <tr class="text-center p-0" *ngIf="header">
    <td style="width: 2.5rem"></td>
    <td class="text-center p-0" style="width: 2.5rem; font-size: 12px">
      <span> 0-1 </span>
    </td>
    <td class="text-center p-0" style="width: 2.5rem; font-size: 12px">1-2</td>
    <td class="text-center p-0" style="width: 2.5rem; font-size: 12px">2-3</td>
    <td class="text-center p-0" style="width: 2.5rem; font-size: 12px">
      Под заказ
    </td>
  </tr>
</table>
<table class="table table-bordered table-sm mb-0">
  <tr class="text-center p-0">
    <td style="width: 2.5rem; font-size: 12px">
      <mat-icon
        style="font-size: 14px !important"
        [matTooltip]="providerInfo.comm1_1"
        matTooltipClass="tooltip"
        >local_shipping</mat-icon
      >
    </td>
    <td
      class="text-center p-0"
      style="width: 2.5rem; font-size: 12px"
      [matTooltip]="providerInfo.comm1_1"
      matTooltipClass="tooltip"
    >
      <span
        style="font-size: 12px"
        [ngStyle]="
          providerInfo.style
            ? { color: 'red', 'font-weight': 'bold', 'font-size': '15px' }
            : ''
        "
      >
        {{ providerInfo.ostatok }}
      </span>
    </td>
    <td
      class="text-center p-0"
      style="width: 2.5rem; font-size: 12px"
      [matTooltip]="providerInfo.comm1_2"
      matTooltipClass="tooltip"
    >
      {{ providerInfo.ostatok2 }}
    </td>
    <td
      class="text-center p-0"
      style="width: 2.5rem; font-size: 12px"
      [matTooltip]="providerInfo.comm1_3"
      matTooltipClass="tooltip"
    >
      {{ providerInfo.ostatok3 }}
    </td>
    <td
      class="text-center p-0"
      style="width: 2.5rem; font-size: 12px"
      [matTooltip]="providerInfo.comm1_4"
      matTooltipClass="tooltip"
    >
      {{ providerInfo.countAll }}
    </td>
  </tr>
  <tr class="text-center p-0">
    <td>
      <mat-icon
        style="font-size: 12px !important"
        [matTooltip]="providerInfo.comm2_1"
        matTooltipClass="tooltip"
        >home_work</mat-icon
      >
    </td>
    <td
      class="text-center p-0"
      [matTooltip]="providerInfo.comm2_1"
      matTooltipClass="tooltip"
    >
      <span
        style="font-size: 12px"
        [ngStyle]="
          providerInfo.style
            ? { color: 'red', 'font-weight': 'bold', 'font-size': '15px' }
            : ''
        "
      >
        {{ providerInfo.ostatok }}
      </span>
    </td>
    <td
      style="font-size: 12px"
      class="text-center p-0"
      [matTooltip]="providerInfo.comm2_2"
      matTooltipClass="tooltip"
    >
      {{ providerInfo.ostatok2 }}
    </td>
    <td
      style="font-size: 12px"
      class="text-center p-0"
      [matTooltip]="providerInfo.comm2_3"
      matTooltipClass="tooltip"
    >
      {{ providerInfo.ostatok3 }}
    </td>
    <td
      style="font-size: 12px"
      class="text-center p-0"
      [matTooltip]="providerInfo.comm2_4"
      matTooltipClass="tooltip"
    >
      {{ providerInfo.countAll }}
    </td>
  </tr>
</table>

<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>Изменить автомобиль.</b></div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>

<mat-card-content>
  <form [formGroup]="formCar" id="formCar" (ngSubmit)="editCar()">
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>VIN код</mat-label>
          <input
            formControlName="vin"
            matInput
            type="text"
            maxlength="17"
            placeholder="_________________"
            minlength="17"
            [mask]="'AAAAAAAAAAAAAAAAA'"
          />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-slide-toggle color="primary" #change formControlName="changeCar">{{
          change.checked ? "Было переоборудование" : "Стандартная комплектация"
        }}</mat-slide-toggle>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field *ngIf="markList$ | async as markList">
          <mat-label>Выберите марку</mat-label>
          <mat-select
            formControlName="mark"
            (selectionChange)="loadModelList()"
          >
            <mat-option *ngFor="let item of markList" [value]="item.text">{{
              item.text
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="formCar.get('mark').hasError('required')">
            Выберите марку
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field *ngIf="modelList$ | async as modelList">
          <mat-label>Выберите модель</mat-label>
          <mat-select formControlName="model">
            <mat-option *ngFor="let item of modelList" [value]="item.text">{{
              item.text
            }}</mat-option>
          </mat-select>
          <mat-error *ngIf="formCar.get('model').hasError('required')">
            Выберите модель
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Выберите Год</mat-label>
          <mat-select formControlName="years">
            <mat-option *ngFor="let item of years" [value]="item">{{
              item
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Выберите Кузов</mat-label>
          <mat-select formControlName="body">
            <mat-option *ngFor="let item of bodyList" [value]="item">{{
              item
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Двигатель</mat-label>
          <input formControlName="engine" matInput />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Модификация</mat-label>
          <input formControlName="mod" matInput />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field
          appearance="outline"
          class="w-100"
          *ngIf="change.checked"
        >
          <mat-label>Комментарий по переоборудованию</mat-label>
          <textarea
            formControlName="changeComment"
            matInput
            rows="3"
          ></textarea>
          <mat-error *ngIf="formCar.get('changeComment').hasError('required')">
            Комментарий обязательный
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-card-content>

<mat-dialog-actions align="end">
  <button mat-button class="btn btn-danger" mat-dialog-close="">Отмена</button>
  <button mat-button class="btn btn-success" type="submit" form="formCar">
    Сохранить
  </button>
</mat-dialog-actions>

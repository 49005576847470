import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IActionState } from 'repositories';

@Component({
  selector: 'lib-list-table',
  templateUrl: 'list-table.component.html',
})
export class ListTableComponent {
  @Input() resultData: any;
  @Input() selected = false;
  @Input() searchFieldList: string[] = [];
  @Input() actionState: IActionState = {
    edit: false,
    delete: false,
  };

  @Output() colClickEvent = new EventEmitter<{ row: any; field: string }>();
  @Output() rowClickEvent = new EventEmitter<{ row: any }>();
  @Output() editClickEvent = new EventEmitter<{ row: any }>();
  @Output() deleteClickEvent = new EventEmitter<{ row: any }>();
  @Output() uploadClickEvent = new EventEmitter<{ row: any }>();
  @Output() addClickEvent = new EventEmitter<{ row: any }>();
  @Output() offEvent = new EventEmitter<{ row: any }>();
  @Output() onEvent = new EventEmitter<{ row: any }>();
  @Output() selectProductEvent = new EventEmitter();

  selectedProduct = [];

  colClick(row: any, field: string): void {
    this.colClickEvent.emit({ row, field });
  }

  rowClick(row: any): void {
    this.rowClickEvent.emit({ row });
  }

  edit(rowData: any): void {
    this.editClickEvent.emit(rowData);
  }

  delete(rowData: any): void {
    this.deleteClickEvent.emit(rowData);
  }

  upload(rowData: any): void {
    this.uploadClickEvent.emit(rowData);
  }
  addProd(rowData: any): void {
    this.addClickEvent.emit(rowData);
  }

  onClick(rowData: any): void {
    this.onEvent.emit(rowData);
  }

  offClick(rowData: any): void {
    this.offEvent.emit(rowData);
  }

  onRowSelect(): void {
    this.selectProductEvent.emit(this.selectedProduct);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogListsComponent } from './catalog-lists.component';
import { MatButtonModule } from '@angular/material/button';
import {
  _MatMenuDirectivesModule,
  MatMenuModule,
} from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CatalogListsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    _MatMenuDirectivesModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
  ],
  exports: [CatalogListsComponent],
})
export class CatalogListsModule {}

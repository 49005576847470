<div *ngIf="info | async as help; else showText">
  <lib-title-modal-window [title]="name"></lib-title-modal-window>

  <div class="row">
    <div class="col" [innerHTML]="help.help"></div>
  </div>
</div>
<ng-template #showText>
  Для данного модуля инструкция еще создается
</ng-template>

import { Injectable } from '@angular/core';
import { Manager, RequestBody, User } from '../interfaces/interfaces';
import { ApiService } from './api.service';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { PermissionUser } from '../../shared/main-layout/core/info-list';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public error$: Subject<string> = new Subject<string>();

  constructor(private api: ApiService, private router: Router) {}

  get userToken(): any {
    const dateToken = new Date(localStorage.getItem('auth-token-date'));
    if (new Date() > dateToken) {
      this.logout();
      return null;
    }
    return localStorage.getItem('auth-token');
  }

  login(user: User): any {
    const q: RequestBody = {
      token: environment.token,
      modelName: 'SprDirect',
      calledMethod: 'onLoginCrm',
      methodProperties: {
        type: 2,
        phone: user.phone,
        password: user.password,
      },
    };

    return this.api
      .post(q)
      .pipe(tap(this.setUserToken), catchError(this.handleError.bind(this)));
  }

  private handleError(error: HttpErrorResponse): any {
    const message = error.error.error;

    switch (message) {
      case 'INVALID_LOGIN_DATA':
        this.error$.next('Неверный логин или пароль');
        break;
      case 'USER_NOT_WORKING':
        this.error$.next('Пользователь не является сотрудником');
        break;
    }
    return throwError(error);
  }

  logout(): void {
    this.setUserToken(null);
    this.router.navigate(['/login']);
  }

  isAuthenticated(): boolean {
    return !!this.userToken;
  }

  private setUserToken(response: Manager | null): void {
    if (response) {
      const tokenDate = new Date(
        new Date().getTime() + +response.tokenData * 1000
      );
      localStorage.setItem('auth-token', response.token);
      localStorage.setItem('auth-token-date', tokenDate.toString());
    } else {
      localStorage.clear();
    }
  }

  getUserPermission(): Observable<PermissionUser> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getUserPermission',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }
}

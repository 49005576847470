import { Directive } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Directive()
export class BaseApiDirective {
  public get apiService(): ApiService {
    return this.api;
  }

  constructor(private readonly api: ApiService) {}

  getRequest(className: string, method: string, value?: any): Observable<any> {
    return this.api.getSite({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }

  postRequest(className: string, method: string, value?: any): Observable<any> {
    return this.api.post_jwt({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }
}

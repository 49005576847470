import {NgModule} from '@angular/core';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {ChartModule} from 'primeng/chart';
import { DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';

@NgModule({
  exports: [
    TableModule,
    ButtonModule,
    ChartModule,
    DropdownModule,
    CalendarModule,
    SliderModule,
    InputTextModule,
    MultiSelectModule,
  ],
})

export class PrimeModule {
}

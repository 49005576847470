<mat-card-title>
  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-10">
      Предложить исправления по бренду
      {{ dataObj.brand }}
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <form [formGroup]="formNomen" (ngSubmit)="saveBrandInfo()">
    <div class="row">
      <div class="col-10">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Предложения по бренду </mat-label>
          <textarea matInput formControlName="commentRec"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <button mat-button class="btn btn-success">Предложить изменения</button>
      </div>
    </div>
  </form>
</mat-card-content>

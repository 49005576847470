<mat-card-title>
  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-10">
      Предложить исправления по номенклатуре<br />
      {{ dataObj.katalNumber }}
      {{ dataObj.brand }}
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <form
    *ngIf="loadInfo$ | async; else loadSpinner"
    [formGroup]="formNomen"
    (ngSubmit)="saveNomenInfo()"
  >
    <div class="row">
      <div class="col-10">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Название (RU)</mat-label>
          <input matInput formControlName="nomenRu" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Название (UA)</mat-label>
          <input matInput formControlName="nomenUa" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Название (EN)</mat-label>
          <input matInput formControlName="nomenEn" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <button mat-button class="btn btn-success">Предложить изменения</button>
      </div>
    </div>
  </form>

  <ng-template #loadSpinner>
    <div class="d-flex justify-content-center my-5">
      <mat-spinner diameter="25"></mat-spinner>
    </div>
  </ng-template>
</mat-card-content>

<button mat-button [matMenuTriggerFor]="menu" class="btn-block">
  <mat-icon>add_shopping_cart</mat-icon>
  <span
    class="btn btn-danger px-2 ml-3"
    style="display: inline-block; font-size: 20px !important"
  >
    {{ orderList.all }}</span
  >
</button>
<mat-menu #menu="matMenu" style="width: 210px !important">
  <a
    *ngFor="let item of orderListInfo"
    mat-menu-item
    [routerLink]="item.link"
    [queryParams]="item.queryParams"
    style="width: 210px"
  >
    <div class="row">
      <div class="col-9">{{ item.title }}</div>
      <div
        class="col-3 btn btn-small btn-danger mt-2"
        style="font-size: 20px !important"
      >
        {{ item.number }}
      </div>
    </div>
  </a>
</mat-menu>

<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2">
      <b>Внимание специнформация о товаре</b>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <p>{{ dialogData.info }}</p>
</mat-card-content>
<mat-card-actions>
  <div class="row justify-content-end">
    <div class="col-auto">
      <button mat-button class="btn btn-danger" matDialogClose>Отмена</button>
    </div>
    <div class="col-auto">
      <button mat-button class="btn btn-success" (click)="acceptInfo()">
        Я ознакомлен
      </button>
    </div>
  </div>
</mat-card-actions>

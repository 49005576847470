import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderService } from '../../core/header.service';

@Component({
  selector: 'lib-header-info',
  templateUrl: './header-info.component.html',
})
export class HeaderInfoComponent implements OnInit {
  name: any;
  info: Observable<{ name: string; help: string }>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private hs: HeaderService
  ) {}

  ngOnInit(): void {
    const url = this.dialogData.url.split('/');
    if (url[url.length - 2] === 'order-card') {
      this.info = this.hs.loadHelpInfo(url[url.length - 2]);
    } else if (url[url.length - 3] === 'user-card-phone') {
      this.info = this.hs.loadHelpInfo(url[url.length - 3]);
    } else {
      this.info = this.hs.loadHelpInfo(url[url.length - 1]);
    }
  }
}

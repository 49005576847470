import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmInterface } from './type/confirm.interface';

@Component({
  selector: 'app-dialog-yes-no',
  templateUrl: './dialog-yes-no.component.html',
  styleUrls: ['./dialog-yes-no.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogYesNoComponent {
  constructor(
    private dialog: MatDialogRef<DialogYesNoComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmInterface
  ) {}

  closeDialog(bool: any): void {
    this.dialog.close({ result: bool });
  }
}

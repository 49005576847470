<div class="container-fluid">
  <div class="row my-3" *ngIf="taskInfo$ | async as taskInfo">
    <div class="col">
      <mat-card class="card-box">
        <mat-card-subtitle>
          <div class="row justify-content-between">
            <div class="col-auto">
              Задача № {{ taskId }} по заказу
              <a
                *ngIf="taskInfo.orderNumber"
                [href]="'/crm/order-card/' + taskInfo.orderNumber"
                class="text-primary"
                target="_blank"
                >{{ taskInfo.orderNumber }}</a
              >
              <a
                *ngIf="taskInfo.hash"
                [href]="'/crm/user-card/' + taskInfo.hash"
                mat-button
                target="_blank"
                class="btn btn-primary ml-3"
                >{{ "SALE.TASK.TASK_CARD.CLIENT_CARD" | translate }}</a
              >
              ({{ taskInfo.taskAction }})
            </div>
            <div class="col-auto" *ngIf="workmanSelfId == taskInfo.workman">
              <div [ngSwitch]="taskInfo.action">
                <div *ngSwitchCase="1">
                  <button
                    type="button"
                    mat-button
                    (click)="setTaskToWork()"
                    class="btn btn-success"
                  >
                    {{ "SALE.TASK.TASK_CARD.BTN_TO_WORK" | translate }}
                  </button>
                </div>
                <div *ngSwitchCase="2">
                  <button
                    (click)="onChangeDate()"
                    mat-button
                    class="btn btn-primary"
                  >
                    {{ "SALE.TASK.TASK_CARD.BTN_CHANGE_DATE" | translate }}
                  </button>

                  <button
                    mat-button
                    class="btn ml-3 btn-success"
                    (click)="onConfirmTask()"
                  >
                    {{ "SALE.TASK.TASK_CARD.BTN_CONFIRM" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-subtitle>
        <mat-card-content>
          <div class="row justify-content-between">
            <div class="col-12 col-md-4">
              <mat-card class="card-box">
                <mat-card-subtitle>{{
                  "SALE.TASK.TASK_CARD.TASK" | translate
                }}</mat-card-subtitle>
                <mat-card-subtitle>
                  <b>{{ taskInfo.task }}</b>
                </mat-card-subtitle>
              </mat-card>
              <mat-card class="card-box mt-2">
                <mat-card-subtitle>{{
                  "SALE.TASK.TASK_CARD.RESULT" | translate
                }}</mat-card-subtitle>
                <mat-card-subtitle>
                  <b>{{ taskInfo.result }}</b>
                </mat-card-subtitle>
              </mat-card>
            </div>
            <div class="col-12 col-md-8">
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{
                      "SALE.TASK.TASK_CARD.DATE_BEGIN" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="datetime-local"
                      [formControl]="dateBegin"
                      readonly
                    />
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{
                      "SALE.TASK.TASK_CARD.DATE_END" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="datetime-local"
                      [formControl]="dateEnd"
                      readonly
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-form-field
                    appearance="outline"
                    style="width: 100%"
                    *ngIf="categoryList$ | async as categoryList"
                  >
                    <mat-label>{{
                      "SALE.TASK.TASK_CARD.CATEGORY" | translate
                    }}</mat-label>
                    <mat-select [formControl]="category" disabled>
                      <mat-option
                        *ngFor="let item of categoryList"
                        [value]="+item.value"
                        >{{ item.text }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>{{
                      "SALE.TASK.TASK_CARD.MANAGER" | translate
                    }}</mat-label>
                    <input matInput [formControl]="manager" readonly />
                  </mat-form-field>
                  <button
                    *ngIf="
                      showEditManager &&
                      +taskInfo.action != 3 &&
                      showEditManager.length > 0
                    "
                    mat-button
                    class="btn btn-primary"
                    (click)="editManager()"
                  >
                    Изменить исполнителя
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col">
              <mat-divider></mat-divider>
            </div>
          </div>

          <div class="row justify-content-between">
            <div class="col-12 col-md-5">
              <mat-card
                class="card-box"
                style="max-height: 400px; overflow-y: auto"
              >
                <mat-card-subtitle>{{
                  "SALE.TASK.TASK_CARD.COMMENTS" | translate
                }}</mat-card-subtitle>
                <mat-card-content *ngIf="taskComment$ | async as taskComment">
                  <div
                    class="row my-2 p-3 align-items-center hover"
                    *ngFor="let item of taskComment"
                  >
                    <div class="col-auto">
                      <div [ngSwitch]="item.action">
                        <mat-icon *ngSwitchCase="2">account_tree</mat-icon>
                        <mat-icon *ngSwitchCase="3">download_done</mat-icon>
                        <mat-icon *ngSwitchCase="1">textsms</mat-icon>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="text-dark"
                        style="font-size: 14px"
                        [innerHTML]="item.comment"
                      ></div>
                      <div class="text-gray">
                        <small>{{ item.date }} - {{ item.manager }}</small>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-12 col-md-5" *ngIf="taskInfo.action == 2">
              <form [formGroup]="form" (ngSubmit)="addComment()">
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-label>{{
                    "SALE.TASK.TASK_CARD.COMMENT" | translate
                  }}</mat-label>
                  <textarea
                    rows="5"
                    formControlName="comment"
                    matInput
                  ></textarea>
                </mat-form-field>
                <button type="submit" mat-button class="btn btn-success">
                  {{ "SALE.TASK.TASK_CARD.BTN_SEND" | translate }}
                </button>
              </form>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBody } from '../../../../../core/interfaces/interfaces';
import { ApiService } from '../../../../../core/services/api.service';

@Injectable()
export class RrplService {
  constructor(private api: ApiService) {}

  loadRequestOrderList(): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadRandomProductRequestToPayment',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }
}

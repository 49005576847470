import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderDopSaleListComponent } from './header-dop-sale-list.component';
import { TitleModalWindowModule } from '../../shared/title-modal-window/title-modal-window.module';
import { MatCardModule } from '@angular/material/card';
import { ListTableModule } from 'list-table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { ExpansionCheckboxModule } from 'shared';

@NgModule({
  declarations: [HeaderDopSaleListComponent],
  imports: [
    CommonModule,
    TitleModalWindowModule,
    MatCardModule,
    ListTableModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    ExpansionCheckboxModule,
  ],
})
export class HeaderDopSaleListModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableData } from '../../interface/table-data';
import { SrvService } from 'repositories';

@Component({
  selector: 'lib-count-table-info',
  templateUrl: './count-table-info.component.html',
})
export class CountTableInfoComponent implements OnInit {
  @Input() resultData: TableData;
  @Output() setTaskWork = new EventEmitter<{ id: number; category: number }>();
  @Output() setToArchive = new EventEmitter<{ id: number }>();
  @Output() showCardRandom = new EventEmitter<any>();

  constructor(public srv: SrvService) {}

  ngOnInit(): void {}

  setTaskToWork(id, category: any): void {
    this.setTaskWork.emit({ id, category });
  }

  setTaskToArchive(rowData: any): void {
    this.setToArchive.emit({ id: rowData.id });
  }

  showCard(rowData: any): void {
    this.showCardRandom.emit(rowData);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RequestBody} from '../../../../core/interfaces/interfaces';
import {ApiService} from '../../../../core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class RsService {

  constructor(
    private api: ApiService
  ) {
  }

  loadInfoMark(mark, dateStart, dateEnd): Observable<any> {
    const q: RequestBody = {
      modelName: 'AnalyticsRomi',
      calledMethod: 'loadInfoMark',
      methodProperties: {
        type: 1,
        mark,
        dateStart,
        dateEnd
      }
    };
    return this.api.post_jwt(q);
  }

  loadInfoModel(model, mark, dateStart, dateEnd): Observable<any> {
    const q: RequestBody = {
      modelName: 'AnalyticsRomi',
      calledMethod: 'loadInfoModel',
      methodProperties: {
        type: 1,
        model,
        mark,
        dateStart,
        dateEnd
      }
    };
    return this.api.post_jwt(q);
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { OtherSpr } from '@core/interfaces/interfaces';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PecService } from '@crm/settings/product/product-card/core/pec.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyService } from '@core/services/destroy.service';
import { SearchService } from '@crm/search/core/search.service';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-add-recommendation-on-cri',
  templateUrl: './add-recommendation-on-cri.component.html',
  providers: [DestroyService, PecService],
})
export class AddRecommendationOnCriComponent implements OnInit {
  form: FormGroup = new FormGroup({
    criRu: new FormControl('', Validators.required),
    valueRu: new FormControl('', Validators.required),
  });
  trigger$ = new Subject();

  filterCriRu: OtherSpr[];
  valueCriRu: OtherSpr[];

  loadProductCriList$: Observable<any> = this.pec.loadCriListInfo().pipe(
    tap((val) => {
      this.filterCriRu = val.criRu;

      if (this.dialogData.nameCi) {
        setTimeout(() => {
          this.form.controls.criRu.setValue({
            text: this.dialogData.nameCi,
            value: this.dialogData.nameCi,
          });
          this.trigger$.next({
            productId: this.dialogData.productId,
            criRu: this.form.value.criRu.value,
          });
        }, 500);
      }
    })
  );
  loadProductLoadCriRuValue$: Observable<OtherSpr[]> = this.trigger$.pipe(
    switchMap((value) =>
      this.pec.loadCriValueRu(value).pipe(
        tap((val) => {
          this.valueCriRu = val;
        })
      )
    )
  );

  constructor(
    private pec: PecService,
    private ss: SearchService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<AddRecommendationOnCriComponent>,
    private des$: DestroyService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit(): void {}

  loadValueRu(): void {
    this.trigger$.next({
      productId: this.dialogData.productId,
      criRu: this.form.value.criRu.value,
    });
  }

  saveNewProductCri(): boolean {
    if (this.form.invalid) {
      this.srv.errorMessage('Неверно заполнена форма');
      return false;
    }

    const q = {
      katalNumber: this.dialogData.katalNumber,
      brand: this.dialogData.brand,
      criRu: this.form.value.criRu.value,
      valueRu: this.form.value.valueRu.value,
    };
    this.ss
      .saveRecProductCri(q)
      .pipe(takeUntil(this.des$))
      .subscribe((value) => {
        this.srv.successMessage('Рекомендация сохранена');
        this.dialogRef.close(true);
      });
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-s-dialog-show-stock-info',
  templateUrl: './s-dialog-show-stock-info.component.html',
  styleUrls: ['./s-dialog-show-stock-info.component.scss']
})
export class SDialogShowStockInfoComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,

  ) { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RandomProductComponent } from './random-product/random-product.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from '../../../../core/modules/material-module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RandomProductComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
})
export class RandomProductModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountTableInfoComponent } from './count-table-info.component';
import { TableModule } from 'primeng/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CountTableInfoComponent],
  imports: [
    CommonModule,
    TableModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [CountTableInfoComponent],
})
export class CountTableInfoModule {}

import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  constructor(private router: Router) {}

  search = new FormControl();

  setSearchProduct(): void {
    if (this.search.value.trim()) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          'crm',
          'search',
          this.search.value.trim().replace('/', ''),
        ])
      );
      this.search.setValue('');
      window.open(url, '_blank');
    }
  }

  ngOnInit(): void {}

  stopLoad($event: MouseEvent): void {
    $event.stopPropagation();
  }
}

<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h4"></div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<form [formGroup]="form" (ngSubmit)="setResponse()">
  <mat-card-content *ngIf="prodInfo$ | async as prodInfo">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="py-0" style="vertical-align: middle">
            Запрос № {{ this.prodInfo.reqNumber }}<br />
            Заказ {{ this.prodInfo.orderNumber }}
          </th>
          <th class="py-0" style="vertical-align: middle" colspan="3">
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Тип запроса</mat-label>
              <mat-select formControlName="reqCat" disabled>
                <mat-option [value]="3">Наличие</mat-option>
                <mat-option [value]="4">Характеристики товара</mat-option>
              </mat-select>
            </mat-form-field>

            <span [ngSwitch]="+this.prodInfo.delivery">
              <mat-icon
                *ngSwitchCase="6"
                matTooltip="Курьером"
                matTooltipClass="tooltip"
                >local_taxi</mat-icon
              >
              <mat-icon
                *ngSwitchCase="7"
                matTooltip="Самовывоз"
                matTooltipClass="tooltip"
                >directions_run</mat-icon
              >
              <mat-icon
                *ngSwitchDefault
                matTooltip="Перевозчик"
                matTooltipClass="tooltip"
                >local_shipping</mat-icon
              >
            </span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="py-0" style="vertical-align: middle">Автор</td>
          <td class="py-0" style="vertical-align: middle" colspan="3">
            {{ prodInfo.logist }}
          </td>
        </tr>
        <tr>
          <td class="py-0" style="vertical-align: middle">Исполнитель</td>
          <td class="py-0" style="vertical-align: middle" colspan="3">
            {{ prodInfo.manager }} Ответить до
            {{ prodInfo.dateEnd | date: "dd-MM-YYYY HH:mm" }}
          </td>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Товар
          </td>
          <th
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
            colspan="3"
          >
            {{ prodInfo.katalNumber }} {{ prodInfo.brand }}
          </th>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Кол-во
          </td>
          <th
            class="py-0"
            colspan="2"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <div
              *ngIf="
                +dialogData.item.text_2.split('|')[0] !=
                  +dialogData.item.text_2.split('|')[1];
                else showCount
              "
            >
              <span
                [ngClass]="{
                  'text-through':
                    +dialogData.item.text_2.split('|')[0] !=
                    +dialogData.item.text_2.split('|')[1]
                }"
              >
                <b>{{ +dialogData.item.text_2.split("|")[0] }} шт</b>
              </span>
              &nbsp;&nbsp;/&nbsp;&nbsp;
              <span
                [ngClass]="{
                  'text-danger':
                    +dialogData.item.text_2.split('|')[0] !=
                    +dialogData.item.text_2.split('|')[1]
                }"
              >
                <b>{{ +dialogData.item.text_2.split("|")[1] }} шт</b>
              </span>
            </div>
            <ng-template #showCount>
              <b>{{ +dialogData.item.text_2.split("|")[1] }} шт</b>
            </ng-template>
          </th>

          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Кол-во</mat-label>
              <mat-select
                formControlName="count"
                (selectionChange)="changeResponse()"
                [disabled]="+dialogData.item.category == 4"
              >
                <mat-option *ngFor="let item of countList" [value]="item">{{
                  item
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Склад
          </td>
          <th
            class="py-0"
            colspan="2"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <div
              *ngIf="
                +dialogData.item.text_1.split('|')[0] !=
                  +dialogData.item.text_1.split('|')[1];
                else showWare
              "
            >
              <span
                [ngClass]="{
                  'text-through':
                    +dialogData.item.text_1.split('|')[0] !=
                    +dialogData.item.text_1.split('|')[1]
                }"
              >
                <b>{{ +dialogData.item.text_1.split("|")[0] }} шт</b>
              </span>
              &nbsp;&nbsp;/&nbsp;&nbsp;
              <span
                [ngClass]="{
                  'text-danger':
                    +dialogData.item.text_1.split('|')[0] !=
                    +dialogData.item.text_1.split('|')[1]
                }"
              >
                <b>{{ +dialogData.item.text_1.split("|")[1] }} шт</b>
              </span>
            </div>
            <ng-template #showWare>
              <b>{{ +dialogData.item.text_1.split("|")[1] }} шт</b>
            </ng-template>
          </th>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
              *ngIf="warehouseList$ | async as warehouseList"
            >
              <mat-label>Склад</mat-label>
              <mat-select
                formControlName="warehouse"
                (selectionChange)="changeResponse()"
                [disabled]="+dialogData.item.category == 4"
              >
                <mat-option
                  *ngFor="let item of warehouseList"
                  [value]="item.value"
                >
                  {{ item.value }} - {{ item.info }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            Срок
          </td>

          <th
            class="py-0"
            colspan="2"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <div
              *ngIf="
                (dialogData.item.text_3.split('|')[0]
                  | date: 'dd-MM-YYYY HH:mm') !==
                  (dialogData.item.text_3.split('|')[1]
                    | date: 'dd-MM-YYYY HH:mm');
                else showDate
              "
            >
              <span
                [ngClass]="{
                  'text-through':
                    (dialogData.item.text_3.split('|')[0]
                      | date: 'dd-MM-YYYY HH:mm') !==
                    (dialogData.item.text_3.split('|')[1]
                      | date: 'dd-MM-YYYY HH:mm')
                }"
              >
                <b
                  >{{
                    dialogData.item.text_3.split("|")[0]
                      | date: "dd-MM-YYYY HH:mm"
                  }}
                </b>
              </span>
              &nbsp;&nbsp;/&nbsp;&nbsp;
              <span
                [ngClass]="{
                  'text-danger':
                    (dialogData.item.text_3.split('|')[0]
                      | date: 'dd-MM-YYYY HH:mm') !==
                    (dialogData.item.text_3.split('|')[1]
                      | date: 'dd-MM-YYYY HH:mm')
                }"
              >
                <b
                  >{{
                    dialogData.item.text_3.split("|")[1]
                      | date: "dd-MM-YYYY HH:mm"
                  }}
                </b>
              </span>
            </div>
            <ng-template #showDate>
              <b
                >{{
                  dialogData.item.text_3.split("|")[1]
                    | date: "dd-MM-YYYY HH:mm"
                }}
              </b>
            </ng-template>
          </th>

          <td
            class="py-0"
            style="vertical-align: middle; border: 3px solid #000 !important"
          >
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Сроки</mat-label>
              <input
                matInput
                formControlName="data"
                type="datetime-local"
                (change)="changeResponse()"
                [readonly]="+dialogData.item.category == 4"
              />
            </mat-form-field>
          </td>
        </tr>

        <tr>
          <td class="py-0" style="vertical-align: middle">Коментарий</td>
          <th class="py-0" style="vertical-align: middle">
            {{ dialogData.item.comment }}
          </th>
          <td class="py-0" style="vertical-align: middle" colspan="2">
            <mat-form-field
              appearance="outline"
              style="width: 100%; margin-bottom: -1.25em"
            >
              <mat-label>Введите комментарий</mat-label>

              <textarea matInput formControlName="comment" rows="3"></textarea>
            </mat-form-field>
          </td>
        </tr>

        <tr>
          <td class="py-0" style="vertical-align: middle">Результат</td>
          <td class="py-0" style="vertical-align: middle" colspan="3">
            <button
              mat-button
              class="btn btn-block"
              [ngClass]="{
                'btn-danger': !styleResponse,
                'btn-success': styleResponse
              }"
            >
              {{ styleResponse ? "Подтвержден" : "Изменен" }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</form>

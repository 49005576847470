import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DestroyService } from '@core/services/destroy.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { SearchService } from '@crm/search/core/search.service';
import { DialogYesNoComponent } from '@shared/dialog-yes-no/dialog-yes-no.component';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OtherSpr } from '@core/interfaces/interfaces';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-add-recommendation-on-cross',
  templateUrl: './add-recommendation-on-cross.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AddRecommendationOnCrossComponent implements OnInit {
  loadCrossProd$: Observable<any> = this.ss.loadCrossList({
    katalNumber: this.dataObj.katalNumber,
    brand: this.dataObj.brand,
  });
  triggerBrand$ = new Subject();

  form: FormGroup = new FormGroup({
    katalNumber: new FormControl('', Validators.required),
    brand: new FormControl('', Validators.required),
    notVerificateOe: new FormControl(false),
  });
  loadBrandList$: Observable<OtherSpr[]> = this.triggerBrand$.pipe(
    debounceTime(500),
    map((i: any) => console.log(i)),
    switchMap(() =>
      this.ss.loadBrandCrossList({ katalNumber: this.form.value.katalNumber })
    )
  );

  constructor(
    private ss: SearchService,
    private srv: SrvService,
    private destroy$: DestroyService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddRecommendationOnCrossComponent>,
    @Inject(MAT_DIALOG_DATA) public dataObj: any // Инжектирование Переменной для приема данных из вне, Объект
  ) {}

  ngOnInit(): void {}

  deleteCross(crossProd: any): void {
    const dialog = this.dialog.open(DialogYesNoComponent, {
      minWidth: '50vw',
      maxWidth: '50vw',
      minHeight: '50vh',
      maxHeight: '50vh',
      data: {
        title: 'Удаление кросс связь',
        text:
          'Вы собираетесь удалить крос связь. Информация вступит в силу после одобрения модератором.<br> <h3>Вы уверены?</h3>',
        dismiss: 'Отмена',
        success: 'Удалить',
      },
    });

    dialog
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter((value) => !!value.result)
      )
      .subscribe(() => {
        this.ss
          .deleteCross({
            katalNumber: this.dataObj.katalNumber,
            brand: this.dataObj.brand,
            crossProd,
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe((value) => {
            if (value && +value.code === 200) {
              this.dialogRef.close();
              this.srv.successMessage('Заявка сохранена');
            }
          });
      });
  }

  loadBrandList(): void {
    this.triggerBrand$.next();
  }

  addNewCross(): boolean {
    if (this.form.invalid) {
      this.srv.errorMessage('Неверно заполненная форма');

      return false;
    }

    this.ss
      .addNewCross(this.form.value, {
        katalNumberProd: this.dataObj.katalNumber,
        brandProd: this.dataObj.brand,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value && +value.code === 200) {
          this.dialogRef.close();

          this.srv.successMessage('Заявка сохранена');
        }
      });
  }
}

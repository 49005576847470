<lib-title-modal-window [title]="title"></lib-title-modal-window>
<mat-card-content *ngIf="loadProdList$|async as loadProdList">
  <div class="row">
    <div class="col-3">
        <ng-container *ngFor="let item of loadProdList.criList">
          <h2>{{item.name}}</h2>

          <lib-expansion-checkbox
            [iFilterMainItem]="item.value"
            [filterForm]="form"
            [control]="criControl"
            (checkChange)="
            onCheckChangeBrand($event.event, $event.num, $event.control)
          "
          ></lib-expansion-checkbox>

        </ng-container>


        <h2>Бренд</h2>
      <lib-expansion-checkbox
        [iFilterMainItem]="loadProdList.brandList"
        [filterForm]="form"
        [control]="brandControl"
        (checkChange)="
            onCheckChangeBrand($event.event, $event.num, $event.control)
          "
      ></lib-expansion-checkbox>

    </div>
    <div class="col-9">
      <lib-list-table [resultData]="productList"
                      [searchFieldList]="searchFieldList"
                      [actionState]="actionState"
      ></lib-list-table>
    </div>
  </div>

</mat-card-content>

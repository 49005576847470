import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSearchUserComponent } from './header-search-user.component';
import { TitleModalWindowModule } from '../../shared/title-modal-window/title-modal-window.module';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [HeaderSearchUserComponent],
  imports: [
    CommonModule,
    TitleModalWindowModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule,
    RouterModule,
    MatButtonModule,
  ],
})
export class HeaderSearchUserModule {}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { HeaderService } from '../../core/header.service';
import { DicList } from '../../interface';

@Component({
  selector: 'lib-catalog-lists',
  templateUrl: './catalog-lists.component.html',
})
export class CatalogListsComponent implements OnInit {
  constructor(private hs: HeaderService) {}

  @Output() showShield = new EventEmitter<string>();

  loadDicInfo$: Observable<DicList> = this.hs.loadDicInfo();

  ngOnInit(): void {}

  onLoadShieldInfo(value: string): void {
    this.showShield.emit(value);
  }

  stopLoad($event: MouseEvent): void {
    $event.stopPropagation();
  }
}

<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2">
      <b>Заказ {{ dialogData.orderList }}</b>
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<div *ngIf="packingOrderLowInfoList$ | async as prodList; else loading">
  <div class="row my-2">
    <div class="col">
      <p-table
        [value]="prodList.productList"
        styleClass=" p-datatable-sm p-datatable-gridlines p-datatable-striped"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem">Заказ</th>
            <th style="width: 20rem">Наименование</th>
            <th style="width: 5rem">Каталожный</th>
            <th style="width: 5rem">Бренд</th>
            <th style="width: 5rem">Кол-во</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-prod>
          <tr>
            <td>{{ prod.zakaz }}</td>
            <td>{{ prod.nomen }}</td>
            <td>{{ prod.katalNumber }}</td>
            <td>{{ prod.brand }}</td>
            <td class="text-center">{{ prod.countProd }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>Количество коробок</mat-label>
        <mat-select [formControl]="countBox">
          <mat-option *ngFor="let item of arrBox" [value]="item">
            {{ item }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-card>
        <mat-card-title> Информация о заказе </mat-card-title>
        <mat-card-content>
          <div
            class="row my-1"
            *ngFor="let item of prodList.managerList"
            style="border-bottom: 1px solid #cecece"
          >
            <div class="col-2">{{ item.order }}</div>
            <div class="col-2">{{ item.manager }}</div>
            <div class="col">
              {{
                item.dateSend + " " + item.timeSend | date: "dd-MM-YYYY HH:mm"
              }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col">
      <mat-card>
        <mat-card-content>
          <div>
            <mat-checkbox color="primary" [formControl]="createTask"
              >Создать задачу на контакт с клиентом</mat-checkbox
            >
          </div>
          <div>
            <mat-checkbox color="primary" *ngIf="info" [formControl]="sendSms"
              >Отправить СМС</mat-checkbox
            >
            <p>
              <b>Шаблон</b> - Замовлення №{{ this.dialogData.orderList }}
              очікує Вас за адресою: вул. Малогончарівська 28/30
            </p>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center my-5">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</ng-template>
<mat-dialog-actions align="end">
  <button class="btn btn-success" mat-button (click)="setPackOrder()">
    Упаковать
  </button>
</mat-dialog-actions>

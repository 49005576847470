import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UclService } from '../../core/ucl.service';
import { Observable } from 'rxjs';
import { OtherSpr } from '../../../../../core/interfaces/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-uc-dialog-add-new-car',
  templateUrl: './uc-dialog-add-new-car.component.html',
  styleUrls: ['./uc-dialog-add-new-car.component.scss'],
  providers: [UclService],
})
export class UcDialogAddNewCarComponent implements OnInit {
  formCar: FormGroup;
  markList$: Observable<OtherSpr[]>;
  modelList$: Observable<OtherSpr[]>;
  years = [];
  bodyList = [
    'Седан',
    'Хетчбек',
    'Универсал',
    'Купе',
    'Кабриолет',
    'Внедорожник',
  ];

  constructor(
    private ucl: UclService,
    private srv: SrvService,
    private dialogRef: MatDialogRef<UcDialogAddNewCarComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.formCar = new FormGroup({
      mark: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      years: new FormControl(''),
      body: new FormControl(''),
      engine: new FormControl(''),
      mod: new FormControl(''),
      vin: new FormControl(''),
      changeComment: new FormControl(''),
      changeCar: new FormControl(''),
    });

    for (let i = 1980; i <= 2021; i++) {
      this.years.push(i);
    }
  }

  ngOnInit(): void {
    this.loadMarkList();
  }

  loadMarkList(): void {
    this.markList$ = this.ucl.loadMarkList();
  }

  loadModelList(): void {
    this.modelList$ = this.ucl.loadModelList(this.formCar.value.mark);
  }

  addNewCar(): any {
    if (this.formCar.invalid) {
      this.srv.errorMessage('Не верно заполнена форма');

      return false;
    }

    const q = {
      mark: this.formCar.value.mark,
      model: this.formCar.value.model,
      years: this.formCar.value.years,
      body: this.formCar.value.body,
      engine: this.formCar.value.engine,
      mod: this.formCar.value.mod,
      vin: this.formCar.value.vin,
      hash: this.dialogData.hash,
      changeCar: this.formCar.value.changeCar,
      changeComment: this.formCar.value.changeComment,
    };

    this.ucl.addNewCar(q).subscribe((res) => {
      this.dialogRef.close({ result: res.code });
    });
  }
}

import { NgModule } from '@angular/core';
import { NotificationComponent } from './component/notification/notification.component';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './component/message/message.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [NotificationComponent, MessageComponent],
  imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule],
  exports: [NotificationComponent],
})
export class NotificationModule {}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { SudService } from '../sud.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OtherSpr } from '../../../../core/interfaces/interfaces';
import { catchError, takeUntil } from 'rxjs/operators';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-dialog-add-new-delivery-api',
  templateUrl: './dialog-add-new-delivery-api.component.html',
  styleUrls: ['./dialog-add-new-delivery-api.component.scss'],
  providers: [SudService],
})
export class DialogAddNewDeliveryApiComponent implements OnInit, OnDestroy {
  form: FormGroup;
  private destroy = new Subject<any>();
  deliveryList$: Observable<OtherSpr[]>;

  constructor(
    private srv: SrvService,
    private sub: SudService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<DialogAddNewDeliveryApiComponent>
  ) {
    this.form = new FormGroup({
      delivery: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      dateStart: new FormControl('', [Validators.required]),
      dateEnd: new FormControl('', [Validators.required]),
      defaultDelivery: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.deliveryList$ = this.sub.onLoadDeliveryList();
  }

  addNewDelivery(): any {
    if (this.form.invalid) {
      this.srv.errorMessage('Не все поля заполнены корректно');
      return false;
    }

    this.sub
      .addNewDelivery(
        this.form.value.delivery,
        this.form.value.value,
        this.form.value.dateStart,
        this.form.value.dateEnd,
        this.form.value.defaultDelivery,
        this.dialogData.suId
      )
      .pipe(
        catchError((err) => {
          this.srv.errorMessage(
            'Ошибка при добавлении API. Обратитесь в техподдержку'
          );
          return of('Error in method onInit addNewDeliveryKey ' + err);
        }),
        takeUntil(this.destroy)
      )
      .subscribe((val) => {
        this.dialogRef.close(val);
        this.srv.successMessage('АПИ ключ сохранен');
      });
  }

  // После получения ответа убиваем поток
  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}

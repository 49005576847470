<mat-card-title>
  <div class="row justify-content-between mb-3">
    <div class="col-auto h2"><b>{{'SALE.TASK.CHANGE_TASK.TITLE'|translate}}</b></div>
    <div class="col-auto">
      <button type="button" mat-icon-button class="btn btn-primary btn-sm"
              matTooltip="Закрыть"
              matTooltipPosition="left"
              matTooltipClass="tooltip"
              matDialogClose>
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close">
      </button>
    </div>
  </div>
</mat-card-title>

<form [formGroup]="form" (ngSubmit)="changeDateTask()">
  <mat-card-content>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>{{'SALE.TASK.CHANGE_TASK.DATE_END'|translate}}</mat-label>
          <input matInput type="date" formControlName="dateEnd" >
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>{{'SALE.TASK.CHANGE_TASK.TIME_END'|translate}}</mat-label>
          <input matInput type="time"  formControlName="timeEnd" >
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" style="width: 100%;" *ngIf="+dialogData.category==1; else anotherReason">
          <mat-label>{{'SALE.TASK.CHANGE_TASK.REASON'|translate}}</mat-label>
          <mat-select formControlName="comment">
            <mat-option value="Нет контакта">Нет контакта</mat-option>
            <mat-option value="Есть контакт, передоговорились">Есть контакт, передоговорились</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-template #anotherReason>
          <mat-form-field appearance="outline" style="width: 100%;" >
            <mat-label>{{'SALE.TASK.CHANGE_TASK.REASON'|translate}}</mat-label>
            <textarea matInput rows="5"  formControlName="comment"></textarea>
          </mat-form-field>
        </ng-template>
      </div>
    </div>

  </mat-card-content>

  <mat-card-actions align="end">
    <button type="submit" class="btn btn-success" mat-button>{{'SALE.TASK.CHANGE_TASK.BTN'|translate}}</button>
  </mat-card-actions>

</form>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatList } from './chat';
import { filter, map } from 'rxjs/operators';
import { ApiService } from '@core/services/api.service';
import { OtherSpr, RequestBody } from '@core/interfaces/interfaces';

@Injectable()
export class ChatService {
  constructor(private api: ApiService) {}

  loadRequestList(id): Observable<ChatList[]> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'loadRequestList',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }
  loadRequestListArchive(id): Observable<ChatList[]> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'loadRequestListArchive',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  loadRequestWarehouseList(): Observable<ChatList[]> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'loadRequestWarehouseList',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  loadRequestWarehouseListArchive(): Observable<ChatList[]> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'loadRequestWarehouseListArchive',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  onCreateNewRequestOrder(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'onCreateNewRequest',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  setResponse(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'setResponse',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }
  getZakazNumberInfo(zakazNumber): Observable<any> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'getZakazNumberInfo',
      methodProperties: {
        type: 1,
        zakazNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  setResponseWarehouse(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'setResponseWarehouse',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }
  loadWarehouse(id?, num?): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getProviderChat',
      methodProperties: {
        type: 1,
        id,
        num,
      },
    };
    return this.api.post_jwt(q).pipe(
      filter((f) => f?.code === 200),
      map(({ response }) => response)
    );
  }

  loadProdInfo(id, resNumber?): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'loadProdInfo',
      methodProperties: {
        type: 1,
        id,
        resNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  closeRequest(num): Observable<any> {
    const q: RequestBody = {
      modelName: 'Chat',
      calledMethod: 'closeRequest',
      methodProperties: {
        type: 1,
        num,
      },
    };
    return this.api.post_jwt(q);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpansionCheckboxComponent } from './expansion-checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ExpansionCheckboxComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  exports: [ExpansionCheckboxComponent],
})
export class ExpansionCheckboxModule {}

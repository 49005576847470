<mat-card-title>
  <div class="row justify-content-between align-items-center mb-3">
    <div class="col-auto">
      Предложить характеристику для <br />{{ dialogData.katalNumber }}
      {{ dialogData.brand }}
    </div>
    <div class="col-auto">
      <button
        type="button"
        mat-icon-button
        class="btn btn-primary btn-sm"
        matTooltip="Закрыть"
        matTooltipPosition="left"
        matTooltipClass="tooltip"
        matDialogClose=""
      >
        <img src="assets/img/icon/close/Icon_Close.svg" alt="close" />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <form
    (ngSubmit)="saveNewProductCri()"
    [formGroup]="form"
    *ngIf="loadProductCriList$ | async as loadProductCriList; else loadSpinner"
  >
    <div class="row">
      <div class="col">
        <mat-form-field
          appearance="outline"
          class="w-100"
          *ngIf="!this.dialogData.nameCi; else showText"
        >
          <mat-label>Выберите критерий из списка</mat-label>
          <mat-select
            formControlName="criRu"
            [placeholder]="'Выберите критерий'"
            (selectionChange)="loadValueRu()"
          >
            <mat-select-filter
              [placeholder]="'Поиск'"
              [displayMember]="'text'"
              [array]="loadProductCriList.criRu"
              (filteredReturn)="filterCriRu = $event"
            >
            </mat-select-filter>
            <mat-option
              style="font-size: 16px !important"
              *ngFor="let item of filterCriRu"
              [value]="item"
            >
              {{ item.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-template #showText>
          <h3>{{ this.dialogData.nameCi }}</h3>
        </ng-template>
      </div>
      <div class="col">
        <mat-form-field
          appearance="outline"
          class="w-100"
          *ngIf="
            loadProductLoadCriRuValue$ | async as loadProductLoadCriRuValue
          "
        >
          <mat-label>Выберите значение из списка</mat-label>
          <mat-select
            formControlName="valueRu"
            [placeholder]="'Выберите критерий'"
          >
            <mat-select-filter
              [placeholder]="'Поиск'"
              [displayMember]="'text'"
              [array]="loadProductLoadCriRuValue"
              (filteredReturn)="valueCriRu = $event"
            >
            </mat-select-filter>
            <mat-option
              style="font-size: 16px !important"
              *ngFor="let item of valueCriRu"
              [value]="item"
            >
              {{ item.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-button class="btn btn-success">предложить</button>
      </div>
    </div>
  </form>
  <ng-template #loadSpinner>
    <div class="d-flex justify-content-center my-5">
      <mat-spinner diameter="25"></mat-spinner>
    </div>
  </ng-template>
</mat-card-content>

<mat-card-title>
  <div class='row justify-content-between align-items-center mb-3'>
    <div class='col-8'>
      Предложить исправления по кросс номерам для<br />{{ dataObj.katalNumber }}
      {{ dataObj.brand }}
    </div>
    <div class='col-auto'>
      <button
        type='button'
        mat-icon-button
        class='btn btn-primary btn-sm'
        matTooltip='Закрыть'
        matTooltipPosition='left'
        matTooltipClass='tooltip'
        matDialogClose=''
      >
        <img src='assets/img/icon/close/Icon_Close.svg' alt='close' />
      </button>
    </div>
  </div>
</mat-card-title>
<mat-card-content>
  <div
    class='container-fluid pt-4'
    *ngIf='loadCrossProd$ | async as loadCrossProd; else loadSpinner'
  >
    <div class='row'>
      <div class='col-12'>
        <h3>Добавить кросс связь</h3>
        <form [formGroup]='form' (ngSubmit)='addNewCross()'>
          <div class='row'>
            <div class='col'>
              <mat-form-field appearance='outline' class='w-100'>
                <mat-label>Каталожный номер</mat-label>
                <input
                  matInput
                  formControlName='katalNumber'
                  (keydown)='loadBrandList()'
                />
              </mat-form-field>
            </div>
            <div class='col'>
              <mat-form-field
                appearance='outline'
                class='w-100'
                *ngIf='loadBrandList$ | async as loadBrandList'
              >
                <mat-label>Бренд</mat-label>
                <mat-select formControlName='brand'>
                  <mat-option
                    *ngFor='let item of loadBrandList'
                    [value]='item.value'
                  >{{ item.text }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <mat-checkbox color='primary' formControlName='notVerificateOe'>Не підтверджено виробником</mat-checkbox>
            </div>
          </div>
          <div class='row'>
            <div class='col'>
              <button mat-button class='btn btn-success'>Добавить</button>
            </div>
          </div>
        </form>
      </div>

      <div class='col-12'>
        <table class='table table-bordered'>
          <thead>
          <tr>
            <th>Каталожный</th>
            <th>Бренд</th>
            <th width='2rem'></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor='let item of loadCrossProd.crossList'>
            <td>{{ item.katalNumber }}</td>
            <td>
              {{ item.brand }}
              <span class='text-danger'>{{
                +item.oe == 2 ? " OE " : ""
                }}</span>
            </td>

            <td>
              <mat-icon
                (click)='deleteCross(item.crossProd)'
                class='cursor-pointer'
                matTooltip='Удалить крос связь'
                matTooltipClass='tooltip'
              >
                delete
              </mat-icon>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #loadSpinner>
    <div class='d-flex justify-content-center my-5'>
      <mat-spinner diameter='25'></mat-spinner>
    </div>
  </ng-template>
</mat-card-content>

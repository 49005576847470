import { Component, OnDestroy, OnInit } from '@angular/core';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  constructor(private srv: SrvService) {}

  ngOnInit(): void {
    this.srv.reconectSSE();
    this.srv.startSSE();

    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.srv.reconectSSE();
        this.srv.startSSE();
      } else {
        this.srv.stopSSE();
      }
    });
  }

  ngOnDestroy(): void {
    this.srv.stopSSE();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { PeersInfo } from '../../interface';

@Component({
  selector: 'lib-peers-list',
  templateUrl: './peers-list.component.html',
})
export class PeersListComponent implements OnInit {
  @Input() peersInfo: PeersInfo;

  constructor() {}

  ngOnInit(): void {}
}

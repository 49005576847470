<mat-card-title>
  <b>{{ dialogData.title }}</b>
</mat-card-title>
<mat-card-content>
  <div class="row my-3">
    <div
      class="col"
      style="font-size: 16px !important"
      [innerHTML]="dialogData.text"
    ></div>
  </div>
</mat-card-content>
<mat-dialog-actions align="end">
  <button class="btn btn-danger" mat-button (click)="closeDialog(false)">
    {{ dialogData.dismiss }}
  </button>
  <button class="btn btn-success" mat-button (click)="closeDialog(true)">
    {{ dialogData.success }}
  </button>
  <button
    class="btn btn-warning"
    *ngIf="dialogData.edit"
    mat-button
    (click)="closeDialog('edit')"
  >
    {{ dialogData.edit }}
  </button>
</mat-dialog-actions>

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ISprList } from 'repositories';
import { cloneDeep } from 'lodash';
enum TypeSearch {
  START_WITH = 'startWith',
  ENTRY_IN = 'entryIn',
}

@Component({
  selector: 'lib-select-with-filter',
  templateUrl: './select-with-filter.component.html',
  styleUrls: ['./select-with-filter.component.scss'],
})
export class SelectWithFilterComponent {
  @Input()
  public set list(list: ISprList[]) {
    this._list = cloneDeep(list);
    this._listBase = cloneDeep(list);
  }

  public get list(): ISprList[] {
    return this._list;
  }

  @Input() label = '';
  @Input() placeholder = '';
  @Input() typeSearch: TypeSearch = TypeSearch.START_WITH;

  public searchCity: FormControl = new FormControl('');
  public filterText: FormControl = new FormControl('');

  public _list: ISprList[] = [];
  private _listBase: ISprList[] = [];

  onKey(): void {
    this._list = this.search(this.filterText.value);
  }

  search(value: string): ISprList[] {
    const filter = value.toLowerCase();

    if (!filter) {
      return this._listBase;
    }

    switch (this.typeSearch) {
      case TypeSearch.ENTRY_IN:
        return this._listBase.filter(
          (item: ISprList) =>
            item.data.toString().toLowerCase().indexOf(filter) !== -1
        );
      default:
        return this._listBase.filter((item: ISprList) =>
          item.data.toString().toLowerCase().startsWith(filter)
        );
    }
  }
}
